export const polishTranslation = {
    auth: {
        // Auth login
        'GoogleAuthMessage': 'Logowanie za pośrednictwem konta Google',
        'Login': 'Logowanie',
        'Password': 'Hasło',
        'RegistrationLink': 'Rejestracja',
        'InputValidationRequiredMessage': 'Pole obowiązkowe do wprowadzenia',
        'BrowserListMessage': 'Użyj jednej z przeglądarek internetowych z tej listy:',
        // Auth registration
        'RegistrationAnnotationMessage': 'Podaj swój adres e-mail i krótki opis. Skontaktujemy się z Tobą!',
        'EmailAddress': 'Adres e-mail',
        'UserRegistrationMessage': 'Opis',
        'LoginLink': 'Zaloguj się',
        'AcceptButton': 'Następny',
        // Auth thank you pop-up
        'ThankYouPopUpMessage': 'Dziękujemy, wkrótce się skontaktujemy!',
        'ReturnButton': 'Wróć',
    },
    mainLeftSideBar: {
        'Retention': 'Zatrzymanie',
        'Campaigns': 'Kampanie',
        'Templates': 'Szablony',
        'Integrations': 'Integracje',
        'Recommendations': 'Rekomendacje',
        'Audiences': 'Audytorium',
        'Analytics': 'Analiza',
        'Instructions': 'Instrukcje',
        'Settings': 'Ustawienia'
    },
    mainHeader: {
       'LaunchScenarioMessage': 'Gotów przyciągnąć swoich klientów?',
       'LaunchScenarioButtonName': 'Uruchom scenariusz',
    },
    mainHeaderBreadcrumbs: {
        'home': 'Strona główna',
        'dashboard': 'Tablica',
        'campaigns': 'Kampanie',
        'goal': 'Cel',
        'conversion': 'Konwersja',
        'cross-cell': 'Sprzedaż krzyżowa',
        'retention': 'Zachowanie',
        'all-scenarios': 'Wszystkie scenariusze',
        'metrics': 'Metryki',
        'custom-webhook': 'Ciało żądania',
        'templates': 'Szablony',
        'edit-template': 'Edytor szablonu',
        'all-templates': 'Wszystkie szablony',
        'email': 'Email',
        'viber': 'Viber',
        'sms': 'SMS',
        'consent': 'Zgody',
        'markup': 'Znacznik',
        'recommendations': 'Rekomendacje',
        'units': 'Jednostki',
        'type': 'Typ',
        'webpushnotify': 'Powiadomienia webpush',
        'mobpushnotify': 'Powiadomienia mobilne',
        'website': 'Banery na stronie internetowej',
        'folder': 'Folder',
        'audiences': 'Audytorie',
        'analytics': 'Analiza',
        'client': 'Klient',
        'integrations': 'Integracje',
        'allEvents': 'Wszystkie wydarzenia',
        'addNewField': 'Dodaj nowe pole',
        'uploadOrders': 'Załaduj zamówienia',
        'uploadOrderItems': 'Załaduj pozycje zamówienia',
        'uploadCustomers': 'Załaduj klientów',
        'uploadProducts': 'Załaduj produkty',
        'communicationFrequencyPolicies': 'Ustawienia częstotliwości komunikacji',
        'communicationConsents': 'Zgody',
        'master': 'Mistrz',
        'masterBased': 'Oparty na mistrzu',
        'billing': 'Wycena',
        'processing': 'Instalacja',
        'settings': 'Ustawienia'
    },
    searchInput: {
        'SearchInputPlaceholder': 'Wyszukaj po nazwie',
        'SearchSegmentPlaceholder': 'Wyszukaj segment',
        'SearchClientPlaceholder': 'Wyszukaj klientów',
        'SearchEventNamePlaceholder': 'Wyszukaj nazwę wydarzenia',
        'SearchPlaceholder': 'Wyszukaj',
        'SearchProductPlaceholder': 'Wyszukaj produkt według nazwy lub artykułu',
    },
    filter: {
        'ScenariosStatusTitle': 'Status scenariusza',
        'TemplateStatusTitle': 'Status szablonu',
        'TemplateToolTitle': 'Narzędzie szablonowe',
        'SelectWebsiteBannerInputs': 'Dostępne typy pól',
        'AllButton': 'Wszystko',
        'ClearButton': 'Wyczyść',
        'Active': 'Aktywny',
        'Stopped': 'Zatrzymany',
        'Ready': 'Gotowy',
        'Draft': 'Szkic',
        'Name': 'Imię',
        'Email': 'Email',
        'Age': 'Wiek',
        'Phone': 'Telefon',
        'ClientEvents': 'Wydarzenia klienta',
        'ProductEvents': 'Wydarzenia produktu',
        'Yes': 'Tak',
        'No': 'Nie',
        'TopicFilter': 'Temat',
        'InternalFilter': 'Wewnętrzny',
        'TemplatesTypeFilter': 'Typ',
        'TemplatesLanguageFilter': 'Język',
        'CrossSell': 'Sprzedaż krzyżowa',
        'WebsiteConversion': 'Konwersja na stronie internetowej',
        'TransactionalMessages': 'Wiadomości transakcyjne',
        'PersonalisedDigests': 'Spersonalizowane skróty',
        'MastersTemplateFilter': 'Mistrzowie',
        'CodeEditorTemplateFilter': 'Edytor kodu',
    },
    scenariosTable: {
        'TableColumnName': 'Nazwa',
        'TableColumnLastChange': 'Ostatnia zmiana',
        'TableColumnMethods': 'Akcje',
        'ConversionNoScenariosMessage': 'Brak scenariuszy konwersji. Utwórz swój pierwszy scenariusz.',
        'CrossSellNoScenariosMessage': 'Brak scenariuszy sprzedaży krzyżowej. Utwórz swój pierwszy scenariusz.',
        'RetentionNoScenariosMessage': 'Brak scenariuszy retencji. Utwórz swój pierwszy scenariusz.',
        'AllScenariosNoScenariosMessage': 'Brak scenariuszy. Utwórz swój pierwszy scenariusz.',
    },
    segmentsTable: {
        'TableColumnName': 'Nazwa',
        'TableColumnLastChange': 'Ostatnia zmiana',
        'TableColumnMethods': 'Akcje',
        'TableColumnAmountClient': 'Liczba klientów',
        'TableColumnEmail': 'Email',
        'TableColumnTelephone': 'Telefon',
        'TableColumnUserId': 'ID użytkownika',
        'TableColumnEventType': 'Typ',
        'TableColumnEvent': 'Wydarzenie',
        'TableColumnDate': 'Data',
        'TableColumnTime': 'Czas',
        'NoName': '',
        'NoRecordToDisplay': 'Brak danych do wyświetlenia',
    },
    scenarioStatuses: {
        'Draft': 'Szkic',
        'Ready': 'Gotowy',
        'Stopped': 'Zatrzymany',
        'Active': 'Aktywny',
        'Library': 'Scenariusz biblioteczny',
    },
    templateStatuses: {
        'Draft': 'Szkic',
        'Ready': 'Gotowy',
    },
    dashboardHeader: {
        'RecommendationUnitStatusTitle': 'Status jednostki rekomendacji:',
        'ScenariosStatusTitle': 'Status scenariusza:',
        'TemplateStatusTitle': 'Status szablonu:',
        'DesignNavLink': 'Projekt',
        'MetricsNavLink': 'Metryki',
        'RequestBodyNavLink': 'Ciało żądania',
        'SqlTriggerNavLink': 'Zapytanie SQL',
        'CancelChangesButton': 'Anuluj zmiany',
        'SaveChangesButton': 'Zapisz',
        'TestButton': 'Testuj',
    },
    actionSidebars: {
        'actionName': 'Nazwa zdarzenia *',
        'actionSubName': 'Podnazwa',
        'maxAudienceSize': 'Maksymalny rozmiar audytorium',
        'NewAttributeFilterButton': '+ Nowy filtr według atrybutów',
        'NewAttributeFilterName': 'Filtr zdarzeń',
        'EventAttributeNameLabel': 'Nazwa atrybutu zdarzenia *',
        'ComparisonOperatorLabel': 'Operator porównania *',
        'ComparisonValueLabel': 'Wartość do porównania',
        'TimeFieldLabel': 'Czas lokalny',
        'NewConditionButton': '+ Utwórz nowy warunek',
        'NewConditionName': 'Warunek',
        'SegmentationFilterLabel': 'Filtr według segmentacji',
        'SegmentationFilterEvent': 'Zdarzenie',
        'SegmentationFilterAttribute': 'Atrybuty',
        'SegmentationFilterMetric': 'Metryki',
        'countDays': 'Liczba dni *',
        'countHours': 'Liczba godzin *',
        'LastTimeIntervalLabel': 'W ostatnim czasie',
        'CurrentTimeIntervalLabel': 'Określony okres czasu',
        'AtScriptStartName': 'Od początku scenariusza',
        'MetricAttributeNameLabel': 'Nazwa metryki *',
        'ClientAttributeNameLabel': 'Nazwa atrybutu klienta *',
        'AggregationFunctionNameLabel': 'Funkcja agregacji',
        'AggregationColumnNameLabel': 'Kolumna agregacji *',
        'waitInterval': 'Liczba minut *',
        'waitToDate': 'Metryka oczekiwania (dni)',
        'dateOffset': 'Przesunięcie dni',
        'waitToTime': 'Metryka oczekiwania godzin',
        'timeOffset': 'Przesunięcie czasu',
        'controlGroup': 'Grupa kontrolna:',
        'aGroupValue': 'A:',
        'bGroupValue': 'B:',
        'shareCount': 'Udział, który zostanie wysłany *',
        'countClients': 'Maksymalna liczba klientów do wysłania *',
        'countTimeIntervalNumber': 'Dla *',
        'evalTimeInterval': 'Wysyłaj równomiernie',
        'CountFunnelTabButton': 'Ilość',
        'ShareFunnelTabButton': 'Udział',
        'RepeatTabButton': 'Powtórzenie',
        'ScheduleTabButton': 'Harmonogram',
        'minutesTimeInterval': 'minut',
        'hoursTimeInterval': 'godzin',
        'daysTimeInterval': 'dni',
        'weeksTimeInterval': 'tygodni',
        'monthTimeInterval': 'miesięcy',
        'EventConditionSplitTabButton': 'Zdarzenie',
        'ClientConditionSplitTabButton': 'Klient',
        'ProductConditionSplitTabButton': 'Produkt',
        'NewProductFilterButton': '+ Nowy filtr według produktu',
        'NewProductFilterName': 'Filtr produktu',
        'ColumnNameOfProductFromEventLabel': 'ID produktu zdarzenia *',
        'ColumnNameFromEventLabel': 'Atrybut produktu zdarzenia *',
        'ColumnNameFromCatalog': 'Atrybut produktu z katalogu *',
        'ReceiverContactEmailLabel': 'Email odbiorcy',
        'ReceiverContactPhoneLabel': 'Telefon odbiorcy',
        'emailMaxCount': 'Maksymalna liczba wysyłek do klienta *',
        'MinDispatchTime': 'Minimalny czas wysyłki *',
        'MaxDispatchTime': 'Maksymalny czas wysyłki *',
    },
    templates: {
        'TabsTemplatesEmailTitle': 'Szablony e-mail',
        'TabsTemplatesSmsTitle': 'Szablony SMS',
        'TabsTemplatesViberTitle': 'Szablony Viber',
        'TabsTemplatesConsentTitle': 'Strona zgody',
        'TabsTemplatesWebsiteBuilderTitle': 'Kreator strony internetowej',
        'TabsTemplatesMobilePushTitle': 'Szablony Mobile Push',
        'TabsTemplatesWebPushTitle': 'Szablony Web Push',
        'SenderNameLabel': 'Nazwa nadawcy *',
        'SenderPhoneLabel': 'Numer nadawcy *',
        'SenderEmailLabel': 'Adres e-mail nadawcy *',
        'SenderMsgTopic': 'Temat wiadomości *',
        'ButtonTextLabel': 'Tekst przycisku',
        'FrontButtonTextLabel': 'Tekst przycisku przedniej strony banera *',
        'FrontButtonColorLabel': 'Kolor przycisku przedniej strony banera (hex) *',
        'BackButtonTextLabel': 'Tekst przycisku tylnej strony banera *',
        'BackButtonColorLabel': 'Kolor przycisku tylnej strony banera (hex) *',
        'BackSideBannerTitleLabel': 'Tytuł formularza kontaktowego tylnej strony banera *',
        'BackSideBannerThanksLabel': 'Wiadomość z podziękowaniem tylnej strony banera *',
        'BodyTextLabel': 'Wiadomość *',
        'UrlButtonLabel': 'Adres URL przycisku',
        'UrlButtonLabelRequired': 'Adres URL przycisku *',
        'TextAreaLabel': 'Tekst *',
        'UploadImageLabel': 'Obrazek 200x400px',
        'UrlImageLabel': 'Adres URL obrazu lub kod Jinja',
        'DeepLinkUrlLabel': 'Głęboki link',
        'UploadBannerLabel': 'Prześlij baner *',
        'CommunicationProviderLabel': 'Dostawca komunikacji',
        'ConsentCategoryLabel': 'Kategoria zgody',
        'TemplateSidebarTitle': 'Szablon',
        'UtmTagsLabel': 'Tagi UTM',
        'BannerPositionLabel': 'Pozycja banera',
        'BannerSizeLabel': 'Rozmiar banera',
        'TitleLabel': 'Tytuł *',
        'TextLabel': 'Tekst *',
        'LinkPushLabel': 'Adres URL push *',
        'TextButtonLabel': 'Tekst przycisku',
        'LinkLabel': 'Adres URL',
        'TtlLabel': 'Czas wyświetlania',
        'TtlSeconds': '(sekundy)',
        'UploadPictureLabel': 'Prześlij obrazek *',
        'UploadLogoLabel': 'Prześlij logo *',
        'UploadLogoSize': 'do 128 kB',
        'UploadChromeLogoLabel': 'Prześlij obrazek dla Google Chrome *',
        'UploadChromeLogoSize': 'do 5,24 MB',
        'EmailLabel': 'Adres e-mail *',
        'PhoneLabel': 'Numer telefonu *',
        'TokenLabel': 'Token *',
        'TitleSendTest': 'Wyślij test',
        'YourMasterTemplates': 'Twoje szablony główne',
        'YourFolders': 'Twoje foldery',
        'YourMasterTemplatesDefaultText': 'Aby pracować szybciej, twórz własne szablony wiadomości e-mail i używaj ich w dowolnym momencie - to łatwe!',
        'YourFoldersDefaultText': 'Zapisz plik, nie zgubisz go nigdy!',
        'ImagesInThisFolder': 'obrazów w tym folder',
        'AddCustomAttributeButton': '+ Dodaj atrybut niestandardowy',
        'AttributeNameLabel': 'Nazwa atrybutu',
        'AttributeValueLabel': 'Wartość atrybutu',
    },
    chartsTitle: {
        'ChartClientTitle': 'Klienci',
        'ChartScenarioConversionTitle': 'Wskaźnik konwersji scenariusza',
        'ChartScenarioRevenueTitle': 'Dochód ze scenariusza',
        'ChartTemplateRevenueTitle': 'Dochód ze szablonu',
        'ChartSendConversionTitle': 'Wysłane/Przekierowane do strony',
        'ChartNumberOfPurchasesTitle': 'Liczba zakupów',
    },

    metrics: {
        'ScenarioMetricsTitle': 'Metryki scenariusza',
        'CommunicationsMetricsTitle': 'Metryki komunikacji',
        'TemplateMetricsTitle': 'Metryki szablonu',
        'MetricsScenarioEntered': 'Wejścia do scenariusza',
        'MetricsCompletedPurchases': 'Zakończone zakupy',
        'MetricsConversionRate': 'Wskaźnik konwersji',
        'MetricsRevenue': 'Dochód',
        'MetricsSendMessages': 'Wysłane wiadomości',
        'MetricsSiteConversion': 'Konwersja na stronie',
        'MetricsABGroup': 'Grupa A/B',
        'MetricsAllClients': 'Wszyscy klienci w scenariuszu',
        'MetricsMessage': 'Wiadomości',
        'MetricsConversion': 'Konwersje',
        'MetricsOpenedMessages': 'Otwarte wiadomości',
        'MetricsConversionMessages': 'Przekierowania z wiadomości',
        'MetricsReceivedMessages': 'Dostarczone wiadomości',
        'MetricsUnsubscribedClients': 'Klienci zrezygnowali z subskrypcji',
        'MetricsTemplateRevenue': 'Dochód ze szablonu',
        'MetricsClientMoved': 'Przeniesieni klienci',
        'MetricsThrown': 'Porzucone',
    },
    metricsTable: {
        'TableColumnActionName': 'Nazwa działania',
        'TableColumnTemplateName': 'Nazwa szablonu',
        'TableColumnMsgSend': 'Wysłane',
        'TableColumnDelivered': 'Dostarczone',
        'TableColumnOpened': 'Otwarte',
        'TableColumnConversion': 'Konwersja',
        'TableColumnUnsubscribed': 'Wypisani',
        'TableColumnRevenue': 'Przychód',
        'TableNoDataMessage': 'Brak danych',
        'TableColumnClicked': 'Kliknięcia',
        'TableColumnPurchased': 'Zakupione',
    },

    consentHeader: {
        'Markup': 'Markup',
        'Preview': 'Podgląd',
        'CancelChangesButton': 'Anuluj zmiany',
        'SaveButton': 'Zapisz',
    },
    popUpsSystemMessages: {
        'AddImageTitle': 'Dodaj obraz',
        'CancelChangesScenarioText': 'Wszystkie zmiany zostały pomyślnie anulowane!',
        'CancelChangesScenarioTitle': 'Anulowanie zmian w scenariuszu',
        'CancelChangesTemplateText': 'Czy na pewno chcesz anulować zmiany w szablonie?',
        'CancelChangesTemplateTitle': 'Anuluj zmiany w szablonie',
        'ChangeScenarioNameText': 'Uwaga! Zmiany zaczną obowiązywać po pełnym zapisaniu scenariusza!',
        'ChangeScenarioNameTitle': 'Zmiana nazwy scenariusza',
        'ChangeTemplateNameText': 'Uwaga! Zmiany zaczną obowiązywać po pełnym zapisaniu szablonu!',
        'ChangeTemplateNameTitle': 'Zmiana nazwy szablonu',
        'ChangeUnitNameText': 'Uwaga! Zmiany zaczną obowiązywać po całkowitym zapisaniu jednostki!',
        'ChangeUnitNameTitle': 'Zmiana nazwy jednostki',
        'CloseChangesTemplateText': 'Nie zapisałeś zmian w szablonie. Zamknąć bez zapisywania?',
        'CloseChangesTemplateTitle': 'Zamykanie Edytora szablonów',
        'CloseEditScenarioText': 'Czy chcesz opuścić stronę edycji scenariusza bez zapisywania?',
        'CloseEditScenarioTitle': 'Dokonano zmian w bieżącym scenariuszu!',
        'ConnectWebsiteBannerScript': 'Podłączenie skryptu do serwisu',
        'ConnectWebsiteBannerScriptMessage': 'Skopiuj i wklej link skryptu do swojej witryny przed tagiem zamykającym treść na stronie głównej.',
        'CopySegmentText': 'Kopia segmentu została pomyślnie utworzona',
        'CopySegmentTitle': 'Kopiowanie segmentu',
        'CopyTemplateText': 'Kopia szablonu została pomyślnie utworzona',
        'CopyTemplateTitle': 'Kopiowanie szablonu',
        'CreateEmailTemplateModalCodeEditorDesc': 'Użyj kodu HTML i JINJA.',
        'CreateEmailTemplateModalCodeEditorTitle': 'Przez edytor kodu',
        'CreateEmailTemplateModalMasterDesc': 'Skorzystaj z naszej biblioteki mistrzów',
        'CreateEmailTemplateModalMasterTitle': 'Przez mistrzów',
        'CreateEmailTemplateModalTitle': 'Utwórz nowy szablon',
        'CreateNewFolder': 'Stworzyć nowy folder',
        'CreateNewMaster': 'Utwórz nowego mistrza',
        'DefaultPopUpsText': 'Domyślny tekst',
        'DefaultPopUpsTitle': 'Domyś',
        'DeleteAbTestGroupText': 'Czy na pewno chcesz usunąć tę grupę testów A/B?',
        'DeleteAbTestGroupTitle': 'Usuwanie grupy testów A/B',
        'DeleteFolderLabel': 'Wprowadź nazwę folderu, aby potwierdzić',
        'DeleteIntegrationEventText': 'Czy na pewno chcesz usunąć to wydarzenie?',
        'DeleteIntegrationEventTitle': 'Usuwanie wydarzenia',
        'DeleteIntegrationFieldText': 'Czy na pewno chcesz usunąć to pole?',
        'DeleteIntegrationFieldTitle': 'Usuń pole',
        'DeleteMasterLabel': 'Wprowadź nazwę mastera, aby potwierdzić',
        'DeleteModalTitle': 'Czy jesteś pewien, że chcesz usunąć?',
        'DeletePolicyText': 'Czy na pewno chcesz usunąć tę politykę?',
        'DeletePolicyTitle': 'Usuń zasadę',
        'DeleteRecommendationUnitText': 'Czy na pewno chcesz usunąć tę jednostkę?',
        'DeleteRecommendationUnitTitle': 'Usuwanie jednostki rekomendacji',
        'DeleteScenarioConfirmText': 'Czy na pewno chcesz usunąć ten scenariusz?',
        'DeleteScenarioPreventText': 'Nie można usunąć scenariusza, który jest używany w eksperymencie. Aby usunąć scenariusz, należy go wyłączyć.',
        'DeleteScenarioTitle': 'Usuwanie scenariusza',
        'DeleteSegmentText': 'Czy na pewno chcesz usunąć ten segment?',
        'DeleteSegmentTitle': 'Usuń segment',
        'DeleteTemplatePreventText': 'Nie można usunąć szablonu, który jest używany w scenariuszu. Aby usunąć szablon, należy go wyłączyć.',
        'DeleteTemplateText': 'Czy na pewno chcesz usunąć ten szablon?',
        'DeleteTemplateTitle': 'Usuwanie szablonu',
        'ErrorAlerterTitle': 'Ups... coś poszło nie tak 🧐',
        'FillableAlerterButton': 'Prześlij obraz',
        'FillableAlerterLabel': 'Alternatywna nazwa obrazu',
        'FillableAlerterPlaceholder': '* Pole nieobowiązkowe',
        'FolderName': 'Nazwa folderu',
        'ImageUploadAlerter': 'Upewnij się, że przesyłany obraz jest mniejszy niż 5,00 MB \n i ma dopuszczalny format .jpg, .gif, .png, .pictures',
        'ImageUploadTechnicalMessage': 'Wszystkie obrazy większe niż 5,24 MB zostaną automatycznie zignorowane. Maksymalna liczba przesłanych zdjęć to 10.',
        'Images': 'obrazy',
        'InputRequiredField': 'Pole obowiązkowe',
        'InvalidOrEmptyFolderName': 'Nieprawidłowa lub pusta nazwa folderu',
        'InvalidOrEmptyMasterName': 'Nieprawidłowa lub pusta nazwa główna',
        'LogoutTitle': 'Potwierdź wylogowanie',
        'MasterName': 'Imię mistrza',
        'Of': 'z',
        'SaveEditSegmentText': 'Podczas zapisywania zmian w segmencie przetwarzanie danych zajmuje trochę czasu. Proces może potrwać kilka minut',
        'SaveEditSegmentTitle': 'Proszę poczekać, aż zmiany zaczną obowiązywać',
        'SaveScenariosConfirmUpdateText': 'Zaktualizowano listę włączonych scenariuszy',
        'SaveScenariosPreventUpdateText': 'Musisz mieć co najmniej jeden uruchomiony scenariusz',
        'SaveScenariosPreventUpdateTitle': 'Nie można zapisać eksperymentu bez scenariuszy',
        'SaveScenariosTitle': 'Zapisywanie scenariuszy',
        'SaveSegmentText': 'Segment został pomyślnie zapisany',
        'SaveSegmentTitle': 'Zapisywanie segmentu',
        'SaveTemplateText': 'Szablon został pomyślnie zapisany!',
        'SaveTemplateTitle': 'Zapisywanie szablonu',
        'SelectField': 'Wybór pola',
        'SelectMasterTemplateModalDesc': 'Skorzystaj z naszej biblioteki szablonów',
        'SelectMasterTemplateModalTitle': 'Biblioteka mistrza',
        'StartExperimentText': 'Eksperyment został pomyślnie rozpoczęty!',
        'StartExperimentTitle': 'Запуск эксперимента',
        'UpdateScenarioText': 'Scenariusz został pomyślnie zapisany w bazie',
        'UpdateScenarioTitle': 'Zapisywanie scenariusza',
        'UpdateUnitText': 'Jednostka została pomyślnie zapisana w bazie danych',
        'UpdateUnitTitle': 'Zapisywanie jednostki',
        'UpdatedPolicyText': 'Zasady zostały pomyślnie zapisane!',
        'UpdatedPolicyTitle': 'Zapisywanie zasad',
        'UploadSelectedImages': 'Ładowanie obrazów...',
        'WarningSaveEventSchemaText': 'Do schematu zdarzeń dodajesz pola powtarzalne lub pola rekordów. Nie będzie można pobrać danych CSV dla takich wydarzeń',
        'WarningTitle': 'Ostrzeżenie',
        'WhereToUploadImage': 'Wybierz miejsce, w którym chcesz dodać obraz',
        'CreateConsentModalInput1Label': 'Nazwa *',
        'CreateConsentModalInput2Label': 'Opis *',
    },
    validationErrorMessages: {
        'CheckboxValidationRequired': 'Wymagane jest wybranie co najmniej jednej opcji',
        'InputValidationRequiredMessage': 'To pole jest wymagane',
        'SelectValidationRequiredMessage': 'Wybierz jedną opcję z listy',
        'PasswordValidationMessage': 'Hasło musi zawierać co najmniej 8 znaków',
        'EmailValidationMessage': 'Podano nieprawidłowy adres e-mail',
        'InputUnacceptableSymbolsValidationMessage': 'To pole zawiera niedozwolone symbole',
        'PositiveNumberInputValidationMessage': 'Liczba musi być większa niż zero',
        'ZeroNumberInputValidationMessage': 'Zero jest niedozwoloną wartością. Jeśli to pole nie jest potrzebne, pozostaw je puste',
        'UrlAddressInputValidationMessage': 'Wprowadź poprawny adres URL',
        'EmailAddressInputValidationMessage': 'Wprowadź poprawny adres e-mail',
        'PhoneInputValidationMessage': 'To pole musi zawierać od 7 do 14 znaków',
        'InputTagsValuesOnlyNumericMessage': 'Dozwolone są tylko wartości liczbowe',
        'UserDoesNotExist': 'Użytkownik nie istnieje',
        'Unauthorized': 'Nieprawidłowe hasło',
        'TemplateValidationMessage': 'Wymagane jest wybranie szablonu',
        'ChooseAtLeastOneOption': 'Wymagane jest wybranie co najmniej jednej opcji',
        'SearchUserMessage': 'Wybierz atrybut z listy'
    },
    actionArrows: {
        'positiveMiddleLabel': 'Tak',
        'negativeMiddleLabel': 'Nie'
    },
    websiteBanner: {
        'NameInputLabel': 'Imię *',
        'EmailInputLabel': 'Adres e-mail *',
        'PhoneInputLabel': 'Numer telefonu *',
        'AgeInputLabel': 'Wiek *',
        'DefaultButtonTextLabel': 'Przejdź',
        'DefaultFeedbackTitleMessage': 'Proszę wypełnić wszystkie pola poniżej',
        'DefaultFeedbackThanksMessage': 'Dziękujemy! Wkrótce skontaktujemy się z Tobą!',
        'DefaultFeedbackButtonText': 'Wyślij',
    },
    tableNoData: {
        'OopsLabel': 'Ups',
        'ScenarioListEmptyLabel': 'Brak scenariuszy',
        'CreateScenarioLabel': 'Utwórz scenariusz',
        'ConditionListEmptyLabel': 'Brak warunków',
        'CreateConditionLabel': 'Dodaj nowy warunek',
        'SegmentsListEmptyLabel': 'Brak segmentów',
        'CreateNewSegment': 'Dodaj nowy segment',
        'ClientsSearchListLabel': 'Znajdź klientów do dodania do segmentu',
        'ClientsEmptyListLabel': 'Brak klientów',
        'AddClientLabel': 'Dodaj klienta',
    },
    default: {
        'EnglishLang': 'English',
        'RussianLang': 'Русский (Украина)',
        'UkrainianLang': 'Українська',
        'PolishLang': 'Polski',
        'LoadingMessage': 'Ładowanie',
        'SaveButtonTitle': 'Zapisz',
        'CancelButtonTitle': 'Anuluj',
        'SkipButtonTitle': 'Pomiń',
        'BackButtonTitle': 'Wstecz',
        'AcceptButtonTitle': 'Potwierdź',
        'ConfirmButtonTitle': 'Potwierdź',
        'ChoiceButtonTitle': 'Wybierz',
        'ReturnButtonTitle': 'Powrót',
        'UploadButtonTitle': 'Prześlij',
        'CreatedButtonTitle': 'Utwórz',
        'CollapseAllButtonTitle': 'Zwiń wszystko',
        'YesButtonTitle': 'Tak',
        'NoButtonTitle': 'Nie',
        'DateInputViewerLabel': 'Określony czas *',
        'EditButtonTitle': 'Edytuj',
        'DeleteButtonTitle': 'Usuń',
        'CopyButtonTitle': 'Skopiuj',
        'LaunchButtonTitle': 'Uruchom',
        'SendButtonTitle': 'Wysłać',
        'StatisticButtonTitle': 'Statystyka',
        'ActionErrorTitle': 'Błąd!',
        'ActionSaveErrorMessage': 'Wszystkie wymagane pola w ustawieniach tego działania muszą być wypełnione.',
        'MondayShortcut': 'Pon',
        'TuesdayShortcut': 'Wt',
        'WednesdayShortcut': 'Śr',
        'ThursdayShortcut': 'Czw',
        'FridayShortcut': 'Pt',
        'SaturdayShortcut': 'Sob',
        'SundayShortcut': 'Nd',
        'MonthJanuary': 'Styczeń',
        'MonthFebruary': 'Luty',
        'MonthMarch': 'Marzec',
        'MonthApril': 'Kwiecień',
        'MonthMay': 'Maj',
        'MonthJune': 'Czerwiec',
        'MonthJuly': 'Lipiec',
        'MonthAugust': 'Sierpień',
        'MonthSeptember': 'Wrzesień',
        'MonthOctober': 'Październik',
        'MonthNovember': 'Listopad',
        'MonthDecember': 'Grudzień',
        'ActionTemplates': 'Szablony akcji',
        'ChromeImage': 'Obraz dla Chrome',
        'WebPushLogo': 'Logo Web push',
        'FirstButton': 'Pierwszy przycisk',
        'SecondButton': 'Drugi przycisk',
        'ActionTemplatePages': 'Lista szablonów',
        'Of': 'z',
        'Close': 'Zamknij',
        'AddButtonLabel': 'Dodaj',
        'UploadFromPc': 'Prześlij z komputera',
        'AddNew': 'Dodaj nowy',
        'BackToTemplatesButton': 'Powrót do Szablonów',
        'DeleteFolderButton': 'Usuń folder',
        'DeleteButton': 'Usuń',
        'ApplyAndEditButton': 'Zastosuj i edytuj',
        'DuplicateAndEdit': 'Duplikuj i edytuj',
        'LaunchExperimentButton': 'Uruchom eksperyment'
    },
    unitStatuses: {
        'AllStatuses': 'Wszystkie',
        'DRAFT': 'Szkic',
        'READY': 'Gotowy',
        'STOPPED': 'Zatrzymany',
        'ACTIVE': 'Aktywny',
        'LEARNING': 'Nauka'
    },
    recommendationUnit: {
        'UnitStatus': 'Status jednostki',
        'RecommendationUnits': 'Jednostki rekomendacyjne',
        'Subject': 'Przedmiot',
        'SubjectRecommendation': 'Rekomendacja przedmiotu',
        'SelectSubjectRecommendation': 'Wybierz rekomendację przedmiotu',
        'SelectRecommendationType': 'Wybierz typ rekomendacji',
        'ButtonAddRecommendationSlot': 'Dodaj slot rekomendacji',
        'Cells': 'Komórki',
        'Slot': 'Slot',
        'AbTestGroup': 'Grupa testu A/B',
        'TotalNumberCells': 'Całkowita liczba komórek',
        'CollaborativeFilteringBasedOnViews': 'Filtrowanie współpracujące na podstawie wyświetleń',
        'CollaborativeFilteringAndDescriptionOfViewed': 'Filtrowanie współpracujące i opis wyświetlanych',
        'EmptySettings': 'Puste ustawienia',
        'ByFilling': 'Według wypełnienia',
        'Empty': 'Pusty',
        'CreateNew': 'Utwórz nowy',
        'PreviouslyUsed': 'Poprzednio używane',
        'ByClient': 'Według klienta',
        'Static': 'Statyczny',
        'Personal': 'Osobisty',
        'SelectMetaType': 'Wybierz typ meta',
        'MostPopularGoods': 'Najpopularniejsze towary',
        'NextGoodsToBuy': 'Następny towar do kupienia',
        'CrossSell': 'Sprzedaż krzyżowa',
        'AccessoriesForSelectedGoods': 'Akcesoria do wybranych towarów',
        'NewType': 'Nowy typ',
        'AddConfigurationBlock': 'Dodaj blok konfiguracji',
        'LearningTime': 'Czas nauki',
        'Ranker': 'Ranker',
        'Order': 'Kolejność'
    },
    subjectRecommendationType: {
        'PRODUCT': 'Produkt',
        'STOCK': 'Promocja',
        'SET': 'Zestaw',
        'ARTICLE': 'Artykuł'
    },
    recommendationMetrics: {
        'Group': 'Grupa',
        'Conversion': 'Konwersja',
        'ClientConversion': 'Konwersja klienta',
        'DirectConversion': 'Bezpośrednia konwersja',
        'DirectClientConversion': 'Bezpośrednia konwersja (klienci)',
        'CTR': 'CTR',
        'ClientCTR': 'CTR klienta',
        'AOV': 'AOV',
        'GroupName': 'Nazwa grupy',
        'unitAllTime': 'Jednostka przez cały czas',
        'ABGroup': 'Grupa A/B',
        'ABGroupsAllTime': 'Grupy A/B przez cały czas',
        'ABGroupsForPeriod': 'Grupy A/B dla okresu',
        'TableNoDataMessage': 'Brak danych',
        'LeadingClientConversionRate': 'Wiodący wskaźnik konwersji klientów',
        'Significance': 'Znaczenie',
        'NotSignificant': 'Nieistotne statystycznie',
        'LeadingDirectClientConversionRate': 'Wiodący wskaźnik bezpośredniej konwersji klientów',
        'Uplift': 'Wzrost',
        'DirectUplift': 'Bezpośredni wzrost',
        'EmptyMetricsForDateRange': 'Brak danych dla wybranego zakresu dat'
    },
    tableRecommendationGroup: {
        'NoName': 'Brak nazwy',
        'RecommendationSlot': 'Slot rekomendacji',
        'Cells': 'Komórki',
        'Actions': 'Akcje',
        'NoSlots': 'Brak slotów',
        'RecommendationType': 'Typ rekomendacji',
        'NumericSymbol': 'Nr',
        'ConfigurationBlocks': 'Bloki konfiguracyjne',
        'NoRecommendationType': 'Brak typów rekomendacji'
    },
    segments: {
        'NewSegment': 'Nowy segment',
        'TypeSegments': 'Typ segmentu',
        'StandardSegmentDescription': 'Konfiguracja za pomocą warunków segmentacji',
        'ManualSegmentDescription': 'Konfiguracja poprzez wyszukiwanie atrybutów klienta',
        'GoThroughTutorial': 'Przejdź przez samouczek',
        'GoThroughTutorialDescription': 'Dowiemy się, jak tworzyć segmenty'
    },
    comparisonSegments: {
        'Segment': 'Segment',
        'Segments': 'Segmenty',
        'Clients': 'Klienci',
        'Add': 'Dodaj',
        'Conditions': 'Warunki',
        'Comparison': 'Porównanie',
        'TotalClients': 'Liczba klientów:',
        'ARPU': 'ARPU:',
        'AverageCheck': 'Średnia wartość zamówienia:',
        'FrequencyPurchasesYear': 'Częstotliwość zakupów w ciągu roku:',
        'FrequencyPurchases30Days': 'Częstotliwość zakupów w ciągu 30 dni:',
        'ChurnRate': 'Wskaźnik utraty klientów:',
        'TotalClientsYear': 'Liczba klientów w ciągu roku:',
        'TotalARPUYear': 'ARPU w ciągu roku:',
        'AverageCheckYear': 'Średnia wartość zamówienia w ciągu roku:',
        'AverageValueSegments': 'Średnia wartość segmentów',
        'ValueCurrentSegment': 'Wartość bieżącego segmentu',
    },
    conditionSegments: {
        'IncludeToSegment': 'Dodaj do segmentu',
        'ExcludeFromSegment': 'Wyklucz z segmentu',
        'Include': 'Dodaj',
        'Exclude': 'Wyklucz',
        'Add': 'Dodaj',
        'SeparateAnd': 'i',
        'SeparateOr': 'lub'
    },
    clientSegment: {
        'PhoneNumber': 'Numer telefonu:',
        'Email': 'Email:',
        'LoyalCardNumber': 'Numer karty lojalnościowej:',
        'UniqueUserId': 'Unikalny identyfikator użytkownika:',
        'UserName': 'Imię:',
        'LastUpdated': 'Ostatnia aktualizacja:',
        'CommunicationChannels': 'Działania innych kanałów komunikacji',
        'MainTabButton': 'Główny',
        'AdditionalTabButton': 'Dodatkowy',
        'Birthday': 'Urodziny',
        'Gender': 'Płeć',
        'UserId': 'Identyfikator użytkownika',
        'DeviceId': 'Identyfikator urzadzenia',
        'ClientId': 'Identyfikator klienta'
    },
    createScenarioSideBar: {
        'TitleNewScenario': 'Nowy scenariusz',
        'TypeScenario': 'Typ scenariusza',
        'EmptyScenarioLabel': 'Pusty scenariusz',
        'EmptyScenarioDescription': 'Bez wcześniej ustawionych parametrów',
        'LibraryScenario': 'Scenariusz biblioteczny'
    },
    fileDropzone: {
        'UploadCsvFile': 'Prześlij plik .csv',
        'UploadJsonFile': 'Prześlij plik .json',
        'DropFileHereLabel': 'Upuść plik tutaj',
        'Bytes': 'bajtów',
        'DragAndDrop': 'Przeciągnij i upuść',
        'YourImage': 'Twoje zdjęcie'
    },
    integrationsTable: {
        'TableColumnEventName': 'Nazwa zdarzenia',
        'TableColumnFieldName': 'Nazwa pola',
        'TableColumnType': 'Typ',
        'TableColumnIsRequired': 'Wymagane',
        'TableColumnActions': 'Akcje',
        'TableColumnIsInternal': 'Wewnętrzne',
        'TableColumnTopic': 'Temat'
    },
    integrations: {
        'UploadOrdersLabel': 'Prześlij zamówienia',
        'UploadOrderItemsLabel': 'Prześlij pozycje zamówienia',
        'UploadCustomersLabel': 'Prześlij klientów',
        'UploadProductsLabel': 'Prześlij produkty',
        'ViewSchemaButtonLabel': 'Zobacz schemat',
        'FieldNameLabel': 'Nazwa pola *',
        'TypeLabel': 'Typ * ',
        'RequiredFieldLabel': 'Pole wymagane',
        'UploadCsvHeaderTitle': 'Prześlij plik CSV',
        'LeftButtonUploadCsvHeaderTitle': 'Rozpocznij przesyłanie',
        'RightButtonUploadCsvHeaderTitle': 'Wstecz',
        'CreateDataMappingForEvent': 'Utwórz mapowanie danych dla zdarzenia',
        'CsvUploadTuningSectionNotice': ' Upewnij się, że przesłana tabela CSV zawiera nazwy kolumn w pierwszym wierszu',
        'SetupCsvUploadNotice': '* Widoczne są tylko pierwsze 20 wierszy jako przykład. Wszystkie dane zostaną załadowane',
        'CsvTableDropBoxLabel': 'Upuść plik tutaj',
        'UploadCsvProcessTitle': 'Przesyłanie tabeli CSV',
        'ApiFailureUploadCsvProcessTitle': 'Błąd przesyłania. Spróbuj ponownie',
        'IngestionProcessLabel': 'Proces przesyłania',
        'SuccessfullyUploadMessageFirstPart': 'Pomyślnie przesłano',
        'SuccessfullyUploadMessageSecondPart': 'zdarzeń z pliku CSV',
        'IntegrationsPageTitle': 'Integracje',
        'SeeAllEventsButton': 'Zobacz wszystkie zdarzenia',
        'GoBackToUploadButton': 'Powróć do przesyłania',
        'AddNewEventSidebarTitle': 'Nowe zdarzenie',
    },
    frequencyPolicies: {
        'NewPolicyTitle': 'Nowa polityka',
        'PolicyNameLabel': 'Nazwa *',
        'PolicyMaximumCommunicationsLabel': 'Maksymalna liczba komunikacji *',
        'PolicyFrequencyLabel': 'Częstotliwość *',
        'FieldName': 'Nazwa pola',
        'MaximumCommunications': 'Maksymalna liczba komunikacji',
        'Frequency': 'Częstotliwość',
        'ScenariosWithPolicy': 'Scenariusze z polityką',
        'Actions': 'Akcje',
        'CommunicationFrequencyTitle': 'Konfiguracja częstotliwości komunikacji',
    },
    mainPage: {
        'Welcome': 'Witaj',
        'WelcomeDesc': 'Poznaj cykl życia swoich klientów, korzystając z analiz retencji klientów NeuCurrent',
        'MainPageTitle': 'Analiza Twojej bazy klientów',
        'MainPageDesc': 'Przetworzymy Twoje dane za pomocą naszego systemu analitycznego, obliczymy wskaźniki retencji, zrozumiemy Twoje bieżące problemy i zaproponujemy rozwiązania',
        'Circle1Desc': 'Zrozum wartość klientów na przestrzeni czasu',
        'Circle2Title': 'Dynamika klientów',
        'Circle2Desc': 'Zrozum cykl życia klienta',
        'Circle3Title': 'Dochód',
        'Circle3Desc': 'Poznaj kluczowe czynniki wpływające na dochody',
        'MainPageSubTitle': 'Strategia retencji klientów',
        'MainPageSubDesc': 'Analizując Twoje dane, identyfikujemy najważniejsze problemy i opracowujemy strategię retencji w celu ich rozwiązania.',
        'Critical': 'Krytyczny',
        'Risk': 'Ryzyko',
        'Opportunity': 'Okazja'
    },
    mainPageDashboard: {
        'Welcome': 'Twoja indywidualna strategia retencji',
        'WelcomeDesc': 'Zbadaj problemy z retencją i rozpocznij interakcję z klientami',
        'RetentionStrategy': 'Strategia retencji',
        'StartWithLabel': 'Rozpocznij',
        'isNotReadySectionMessage': 'Analizując Twoje dane, zidentyfikujemy najważniejsze problemy i stworzymy strategię retencji w celu ich rozwiązania.',
        'isReadySectionMessage': 'Analizując Twoje historyczne dane transakcyjne, zauważamy problemy z retencją klientów, które znacząco wpływają na Twoje przychody.',
        'Critical': 'Krytyczne',
        'Risk': 'Ryzyko',
        'Opportunity': 'Szansa',
        'DropdownSectionCriticalDesc': 'Problemy, które mają duży wpływ na sprzedaż. Wymagają natychmiastowej uwagi.',
        'DropdownSectionRiskDesc': 'Potencjalne problemy krytyczne. Wymagają uwagi, aby nie przekształciły się w istotne negatywne tendencje.',
        'DropdownSectionOpportunityDesc': 'Możliwości przyszłego wzrostu sprzedaży.',
        'DropdownCardInterval': 'Miesięcznie',
        'DropdownCardStatusNew': 'Nowy',
        'DropdownCardStatusScenarioLaunched': 'Scenariusz uruchomiony, oczekujemy wyników',
        'DropdownCardStatusFirstResults': 'Mamy pierwsze wyniki',
        'DropdownCardStatusResolved': 'Rozwiązane',
        'LtvDesc': 'Przychód, który przynosi Ci każdy klient. Dąż do zwiększenia LTV',
        'LtvCard1': 'Długość życia w latach',
        'LtvCard2': 'Częstotliwość zakupów w ciągu roku',
        'RevenueTitle': 'Przychód',
        'RevenueDesc': 'Twoje główne źródła przychodu w ciągu ostatnich 30 dni',
        'RevenueCard1': 'Aktywni klienci w ciągu ostatnich 30 dni',
        'RevenueCard2': 'Częstotliwość okresu',
        'CustomerDynamicsTitle': 'Baza klientów',
        'PopUpCardTitle': 'Opis metryki',
        'CustomerDynamicsElem3': 'Klienci z pojedynczym zakupem',
        'CustomerDynamicsElem6': 'Nowi klienci',
        'CustomerDynamicsElem8': 'Aktywowani',
        'CustomerDynamicsElem10': 'Ryzyko utraty',
        'CustomerDynamicsElem14': 'Subskrypcje',
        'CustomerDynamicsElem16': 'Stracone',
        'CustomerDynamicsElemDays': 'dni',
        'CustomerDynamicsElemDaysAgo': 'dni temu',
        'CustomerDynamicsElemDaysBefore': 'dnia temu',
        'CustomerDynamicsElem3Message1': 'Dokonał 1 zakupu i dokonał go ponad',
        'CustomerDynamicsElem3Message2': 'dni temu, ale mniej niż',
        'CustomerDynamicsElem6Message1': 'Posiada 1 zakup i zakup został dokonany mniej lub równo',
        'CustomerDynamicsElem8Message1': 'Posiada 2 lub więcej zakupów, a ostatni zakup został dokonany mniej niż',
        'CustomerDynamicsElem10Message1': 'Posiada przynajmniej 1 zakup, który został dokonany więcej lub równo',
        'CustomerDynamicsElem10Message2': 'dni temu i mniej',
        'CustomerDynamicsElem14Message1': 'Posiada przynajmniej jeden bezpośredni kanał komunikacji i nie dokonuje zakupów',
        'CustomerDynamicsElem16Message1': 'Dokonał przynajmniej 1 zakupu, który został dokonany więcej lub równo',
        'CustomerDynamicsElem1Message1': 'Nowi klienci w ciągu ostatnich',
        'CustomerDynamicsElem2Message1': 'Singiel w ciągu ostatnich',
        'CustomerDynamicsElem2Message2': 'Nowych',
        'CustomerDynamicsElem4Message1': 'Ryzyko utraty od jednego do ostatniego',
        'CustomerDynamicsElem4Message2': 'Bezpośredni',
        'CustomerDynamicsElem5Message1': 'Aktywny od singla w ciągu ostatnich',
        'CustomerDynamicsElem5Message2': 'Nowych',
        'CustomerDynamicsElem7Message1': 'Aktywny od nowych w ciągu ostatnich',
        'CustomerDynamicsElem7Message2': 'Nowych za ostatni',
        'CustomerDynamicsElem9Message1': 'Ryzyko utraty od Aktywnych w ciągu ostatnich',
        'CustomerDynamicsElem9Message2': 'Aktywny',
        'CustomerDynamicsElem11Message1': 'Nowi, Singiel, Aktywni od subskrybentów w ciągu ostatnich',
        'CustomerDynamicsElem11Message2': 'Subskrybenci',
        'CustomerDynamicsElem12Message1': 'Aktywny z powodu ryzyka utraty w ciągu ostatnich',
        'CustomerDynamicsElem12Message2': 'Ryzyko utraty',
        'CustomerDynamicsElem13Message1': 'Zdezorientowany z powodu ryzyka dezorientacji w ciągu ostatnich',
        'CustomerDynamicsElem13Message2': 'Ryzyko utraty',
        'CustomerDynamicsElem15Message1': 'Aktywny z powodu utraty w ciągu ostatnich',
        'CustomerDynamicsElem15Message2': 'Utrata',
        'CustomerDynamicsElem17Message1': 'Nowi subskrybenci w ciągu ostatnich',
    },
    calendar: {
        'AllTimeButton': 'Cały czas',
        'ThisYearButton': 'W tym roku',
        'ThisMonthButton': 'Ten miesiąc',
        'ThisWeekButton': 'W tym tygodniu',
        'ClearButton': 'Jasne',
        'AppleButton': 'Stosować',
        'Selected': 'Wybrany',
        'Days': 'dni'
    },
    clientDashboardPage: {
        'InformationAboutClientTitle': 'Informacja klientów',
        'ConsentsTitle': 'Zgoda klienta',
        'ClientKpiTitle': 'Stan klienta',
        'TableOfEvents': 'Wydarzenia',
        'FilterDropdown': 'Filtr',
        'SortDropdown': 'Sortowanie',
        'PeriodButton': 'Okres:',
        'PeriodButtonAllTimeLabel': 'Cały czas',
        'ActivityStatusLabel': 'Stan aktywności',
        'SubscriptionDateLabel': 'Data subskrypcji',
        'OrderDateLabel': 'Data zamówienia',
        'FirstLabel': 'Pierwszy',
        'LastLabel': 'Ostatni',
        'Order12MonthFrequencyStatusLabel': 'Status częstotliwości zamówień przez 12 miesięcy',
        'OrdersCurrentMonthStatusLabel': 'Status zamówienia na bieżący miesiąc',
        'CustomerMainOrderSourceLabel': 'Główne źródło zamówień sprzedaży',
        'CustomerMainPosLabel': 'Główny klient POS',
        'OrderMetricsTitle': 'Metryki zamówienia',
        'OverallColumn': 'Ogólny',
        'Last12MonthColumn': 'Ostatnie 12 miesięcy',
        'CurrentPeriodColumn': 'Obecny okres',
        'OrderCountRow': 'Liczba zamówień',
        'OrdersSumRow': 'Ilość zamówień',
        'AovRow': 'AOV',
        'OrdersProductsQuantityRow': 'Ilość zamawianych produktów',
        'CommunicationChannelActive': 'Klient przeszedł przez kanał co najmniej raz w ciągu ostatnich 3 miesięcy',
        'CommunicationChannelNotActive': 'Komunikacja została wysłana do tego kanału, ale klient nie przeszedł przez ten kanał',
        'CommunicationChannelUndefined': 'Stan kanału nieznany (w ciągu ostatnich 12 miesięcy na ten kanał nie dostarczono żadnych wiadomości)',
        'CommunicationChannelFailed': 'Niedostarczenie komunikacji na tym kanale',
        'CommunicationChannelNotAvailable': 'Ten atrybut nie jest dostępny dla klienta',
        'TooltipExternalEvents': 'Zdarzenia wywołane aktywnością klienta poza platformą NeuCurrent. Użytkownicy platformy mogą również dodawać do platformy swoje spersonalizowane wydarzenia. Wszystkie inne wydarzenia niesklasyfikowane mają charakter zewnętrzny',
        'TooltipWebsiteActivity': 'Aktywność Klienta na stronie',
        'TooltipMobileAppActivity': 'Aktywność klienta w aplikacji',
        'TooltipCommunications': 'Zdarzenia związane z komunikacją z klientami',
        'TooltipPlatformEvents': 'Zdarzenia wyzwalane na platformie'
    },
    iconicDropdown: {
        'Newest': 'Nowy',
        'Oldest': 'Stary'
    },
    templatesSetupAside: {
        'MasterAsideTitle': 'Twoje główne ustawienia',
        'MasterAsideDesc': 'Skonfiguruj swój główny szablon, wypełniając wszystkie pola poniżej',
        'ImagesAsideTitle': 'Twoje obrazy',
        'ImagesAsideDesc': 'Wybierz, przeslij i usuń potrzebne obrazy w poniższych folderach. Kliknij na obraz, aby skopiować adres URL do schowka.',
        'AsideImagesFoldersTitle': 'Prześlij własne obrazy',
        'SettingsAsideTitle': 'Ustawienia szablonu',
        'SettingsAsideDesc': 'Wypełnij wszystkie pola poniżej, aby skonfigurować swój szablon',
        'TestAsideTitle': 'Testuj swój szablon',
        'TestAsideDesc': 'Wypełnij wszystkie pola poniżej i przetestuj swój szablon'
    },
    policies: {
        'PoliciesLabel': 'Polityka',
        'PoliciesDesc': 'Ustaw częstotliwość komunikacji i wymagania dotyczące zgody dla swoich klientów',
        'FrequenciesButtonTitle': 'Częstotliwości',
        'FrequenciesButtonDesc': 'Jak często chcesz wysyłać komunikaty do swoich klientów?',
        'ConsentsButtonTitle': 'Zgody',
        'ConsentsButtonDesc': 'Ustaw wymagania dotyczące zgody dla swoich klientów',
    },
    metricViewPage: {
        'LaunchExperimentButton': 'Uruchom eksperyment',
        'ProblemsListSectionTitle': 'Problemy związane z tą metryką',
        'PastYearLabel': 'Ostatni rok',
        'CurrentYearLabel': 'W tym roku',
        'DetectedProblemLabel': 'Wykryty problem',
    }
};

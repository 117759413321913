export const englishTranslation = {
    auth: {
        // Auth login
        'GoogleAuthMessage': 'Use your Google account for enter',
        'Login': 'Login',
        'Password': 'Password',
        'RegistrationLink': 'Sign Up',
        'InputValidationRequiredMessage': 'Required field',
        'BrowserListMessage': 'Use a browser from the list:',
        // Auth registration
        'RegistrationAnnotationMessage': 'Provide your e-mail and a short description. We will contact you!',
        'EmailAddress': 'E-mail address',
        'UserRegistrationMessage': 'Description',
        'LoginLink': 'Sign In',
        'AcceptButton': 'Sign In',
        // Auth thank you pop-up
        'ThankYouPopUpMessage': 'Thank you, we will contact you soon!',
        'ReturnButton': 'Return',
    },
    mainLeftSideBar: {
        'Retention': 'Retention',
        'Campaigns': 'Campaigns',
        'Templates': 'Templates',
        'Integrations': 'Integrations',
        'Recommendations': 'Recommendations',
        'Audiences': 'Audiences',
        'Analytics': 'Analytics',
        'Instructions': 'Instructions',
        'Settings': 'Settings'
    },
    mainHeader: {
        'LaunchScenarioMessage': 'Ready to engage your customers?',
        'LaunchScenarioButtonName': 'Launch Scenario'
    },
    mainHeaderBreadcrumbs: {
        'home': 'Home',
        'dashboard': 'Dashboard',
        'campaigns': 'Campaigns',
        'goal': 'Goal',
        'conversion': 'Conversion',
        'cross-cell': 'Cross cell',
        'retention': 'Awareness',
        'all-scenarios': 'All scenarios',
        'metrics': 'Metrics',
        'custom-webhook': 'Request Body',
        'templates': 'Templates',
        'edit-template': 'Template editor',
        'all-templates': 'All Templates',
        'email': 'Email',
        'viber': 'Viber',
        'sms': 'Sms',
        'consent': 'TemplatesConsent',
        'markup': 'Markup',
        'webpushnotify': 'Web push',
        'mobpushnotify': 'Mobile push',
        'website': 'Website builder',
        'folder': 'Folder',
        'audiences': 'Audiences',
        'recommendations': 'Recommendations',
        'units': 'Units',
        'type': 'Type',
        'analytics': 'Analytics',
        'client': 'Customer',
        'integrations': 'Integrations',
        'allEvents': 'All events',
        'addNewField': 'Add field',
        'uploadOrders': 'Upload orders',
        'uploadOrderItems': 'Upload order items',
        'uploadCustomers': 'Upload customers',
        'uploadProducts': 'Upload products',
        'communicationFrequencyPolicies': 'Communication frequency policies',
        'communicationConsents': 'Consents',
        'master': 'Master',
        'masterBased': 'Master based',
        'billing': 'Pricing',
        'processing': 'Installation',
        'settings': 'Settings'
    },
    searchInput: {
        'SearchInputPlaceholder': 'Search by filter',
        'SearchSegmentPlaceholder': 'Search segment',
        'SearchClientPlaceholder': 'Search a customer',
        'SearchEventNamePlaceholder': 'Search by event name',
        'SearchPlaceholder': 'Search',
        'SearchProductPlaceholder': 'Search product by name or article',
    },
    filter: {
        'ScenariosStatusTitle': 'Scenario status',
        'TemplateStatusTitle': 'Template status',
        'TemplateToolTitle': 'Template tool',
        'SelectWebsiteBannerInputs': 'Select input type',
        'AllButton': 'All',
        'ClearButton': 'Clear',
        'Active': 'Active',
        'Stopped': 'Inactive',
        'Ready': 'Ready',
        'Draft': 'Draft',
        'Name': 'Name',
        'Email': 'Email',
        'Age': 'Age',
        'Phone': 'Phone',
        'ClientEvents': 'Client events',
        'ProductEvents': 'Product events',
        'Yes': 'Yes',
        'No': 'No',
        'TopicFilter': 'Topic',
        'InternalFilter': 'Internal',
        'TemplatesTypeFilter': 'Type',
        'TemplatesLanguageFilter': 'Language',
        'CrossSell': 'Cross sell',
        'WebsiteConversion': 'Website conversion',
        'TransactionalMessages': 'Transactional messages',
        'PersonalisedDigests': 'Personalised digests',
        'MastersTemplateFilter': 'Masters',
        'CodeEditorTemplateFilter': 'Code editor',
    },
    scenariosTable: {
        'TableColumnName': 'Scenario name',
        'TableColumnLastChange': 'Last change',
        'TableColumnMethods': 'Actions',
        'ConversionNoScenariosMessage': 'There are no conversions scenarios. Create your first scenario.',
        'CrossSellNoScenariosMessage': 'There are no cross-selling scenarios. Create your first scenarios.',
        'RetentionNoScenariosMessage': 'There are no reminders scenarios. Create your first scenarios.',
        'AllScenariosNoScenariosMessage': 'There are no scenarios. Create your first scenarios.'
    },
    segmentsTable: {
        'TableColumnName': 'Name',
        'TableColumnLastChange': 'Last changes',
        'TableColumnMethods': 'Methods',
        'TableColumnAmountClient': 'Number of customers',
        'TableColumnEmail': 'Email',
        'TableColumnTelephone': 'Telephone',
        'TableColumnUserId': 'Unique user id',
        'TableColumnEventType': 'Type',
        'TableColumnEvent': 'Event',
        'TableColumnDate': 'Date',
        'TableColumnTime': 'Time',
        'NoName': '',
        'NoRecordToDisplay': 'There are no records to display'
    },
    scenarioStatuses: {
        'Draft': 'Draft',
        'Ready': 'Ready',
        'Stopped': 'Stopped',
        'Active': 'Active',
        'Library': 'Library scenario'
    },
    templateStatuses: {
        'Draft': 'Draft',
        'Ready': 'Ready',
    },
    dashboardHeader: {
        'RecommendationUnitStatusTitle': 'Unit status:',
        'ScenariosStatusTitle': 'Scenario status:',
        'TemplateStatusTitle': 'Template status:',
        'DesignNavLink': 'Design',
        'MetricsNavLink': 'Metrics',
        'RequestBodyNavLink': 'Request Body',
        'SqlTriggerNavLink': 'SQL Request',
        'CancelChangesButton': 'Cancel changes',
        'SaveChangesButton': 'Save',
        'TestButton': 'TestPage',
    },
    actionSidebars: {
        'actionName': 'Name of event *',
        'actionSubName': 'Subname',
        'maxAudienceSize': 'Maximum audience size',
        'NewAttributeFilterButton': '+ New attribute filter',
        'NewAttributeFilterName': 'Event filter',
        'EventAttributeNameLabel': 'Event attribute name *',
        'ComparisonOperatorLabel': 'Comparison operator *',
        'ComparisonValueLabel': 'Comparison value',
        'TimeFieldLabel': 'Your local timezone',
        'NewConditionButton': '+ Create new condition',
        'NewConditionName': 'Condition',
        'SegmentationFilterLabel': 'Segmentation filter',
        'SegmentationFilterEvent': 'Event',
        'SegmentationFilterAttribute': 'Attribute',
        'SegmentationFilterMetric': 'Metric',
        'countDays': 'Number of days *',
        'countHours': 'Number of hours *',
        'LastTimeIntervalLabel': 'Last time',
        'CurrentTimeIntervalLabel': 'Specified time',
        'AtScriptStartName': 'Since the launch scenario',
        'MetricAttributeNameLabel': 'Name of metric *',
        'ClientAttributeNameLabel': 'Name of customer attribute *',
        'AggregationFunctionNameLabel': 'Aggregation function',
        'AggregationColumnNameLabel': 'Aggregation column *',
        'waitInterval': 'Number of minutes *',
        'waitToDate': 'Metric waiting (days)',
        'dateOffset': 'Offset days',
        'waitToTime': 'Metric waiting (hours)',
        'timeOffset': 'Offset hours',
        'controlGroup': 'Control group:',
        'aGroupValue': 'A:',
        'bGroupValue': 'B:',
        'shareCount': '% of customers to continue with scenario *',
        'countClients': 'Maximum number of customers to continue with scenario *',
        'countTimeIntervalNumber': 'Set frequency *',
        'evalTimeInterval': 'Distribute evenly',
        'CountFunnelTabButton': 'Count',
        'ShareFunnelTabButton': 'Share',
        'RepeatTabButton': 'Repeat',
        'ScheduleTabButton': 'Schedule',
        'minutesTimeInterval': 'Minutes',
        'hoursTimeInterval': 'Hours',
        'daysTimeInterval': 'Days',
        'weeksTimeInterval': 'Weeks',
        'monthTimeInterval': 'Month',
        'EventConditionSplitTabButton': 'Event',
        'ClientConditionSplitTabButton': 'Client',
        'ProductConditionSplitTabButton': 'Product',
        'NewProductFilterButton': '+ New product filter',
        'NewProductFilterName': 'Product filter',
        'ColumnNameOfProductFromEventLabel': 'Item ID from the event *',
        'ColumnNameFromEventLabel': 'Item attribute from event *',
        'ColumnNameFromCatalog': 'Catalog item attribute *',
        'ReceiverContactEmailLabel': 'Email address',
        'ReceiverContactPhoneLabel': 'Receiver phone number',
        'emailMaxCount': 'Maximum number of communications per customer *',
        'MinDispatchTime': 'Earliest time to send *',
        'MaxDispatchTime': 'Latest time to send *',
        'NewJsonHeaderButton': '+ New Header',
        'MaxShowing': 'Maximum showing *',
        'impressionsNumber': 'Number of impressions',
        'DisplayDateLabel': 'Latest display date',
        'NewUrlButton': '+ New Url',
        'RangeOfDatesInputLabel': 'Time interval *',
        'templateCache': 'Cache template',
        'ConditionName': 'Condition name *',
        'Conditions': 'Conditions',
        'Segments': 'Segments',
        'MadePurchaseSilmplifiedSettings': 'Made a purchase',
        'AddToBasketSilmplifiedSettings': 'Add to basket',
        'ViewedProductSilmplifiedSettings': 'Viewed a product',
        'ReadEmailSilmplifiedSettings': 'Read email',
        'ReadViberSilmplifiedSettings': 'Read viber',
        'ReadSmsSilmplifiedSettings': 'Read sms',
        'TimeInterval': 'Time interval *',
        'CommunicationPolicyLabel': 'Communication frequency policy *'
    },
    templates: {
        'TabsTemplatesEmailTitle': 'Email templates',
        'TabsTemplatesSmsTitle': 'SMS templates',
        'TabsTemplatesViberTitle': 'Viber templates',
        'TabsTemplatesConsentTitle': 'Consent page',
        'TabsTemplatesWebsiteBuilderTitle': 'Website builder',
        'TabsTemplatesMobilePushTitle': 'Mobile Push templates',
        'TabsTemplatesWebPushTitle': 'Web Push templates',
        'SenderNameLabel': 'Sender name *',
        'SenderPhoneLabel': 'Sender phone *',
        'SenderEmailLabel': 'Sender email *',
        'SenderMsgTopic': 'Message topic *',
        'ButtonTextLabel': 'Button text',
        'FrontButtonTextLabel': 'Front side button text *',
        'FrontButtonColorLabel': 'Front side button color (hex) *',
        'BackButtonTextLabel': 'Reverse side button text *',
        'BackButtonColorLabel': 'Reverse side button color (hex) *',
        'BackSideBannerTitleLabel': 'Feedback banner title *',
        'BackSideBannerThanksLabel': 'Feedback gratitude message *',
        'BodyTextLabel': 'Message *',
        'UrlButtonLabel': 'Button url',
        'UrlButtonLabelRequired': 'Button url *',
        'TextAreaLabel': 'Message *',
        'UploadImageLabel': 'Image 200x400px',
        'UrlImageLabel': 'Image url or Jinja code',
        'DeepLinkUrlLabel': 'Deep link',
        'UploadBannerLabel': 'Upload banner *',
        'CommunicationProviderLabel': 'Communication provider',
        'ConsentCategoryLabel': 'Consent сategory',
        'TemplateSidebarTitle': 'Template',
        'UtmTagsLabel': 'UTM-tags',
        'BannerPositionLabel': 'Banner position',
        'BannerSizeLabel': 'Banner size',
        'TitleLabel': 'Title *',
        'TextLabel': 'Text *',
        'LinkPushLabel': 'Link for push *',
        'TextButtonLabel': 'Button text',
        'LinkLabel': 'Link',
        'TtlLabel': 'Notification time to live',
        'TtlSeconds': '(seconds)',
        'UploadLogoLabel': 'Logo *',
        'UploadPictureLabel': 'Picture *',
        'UploadLogoSize': 'up tp 128 kB',
        'UploadChromeLogoLabel': 'Image for Google Chrome*',
        'UploadChromeLogoSize': 'up to 5.24 MB',
        'EmailLabel': 'Email *',
        'PhoneLabel': 'Phone *',
        'TokenLabel': 'Token *',
        'TitleSendTest': 'Send test',
        'YourMasterTemplates': 'Your master templates',
        'YourFolders': 'Your folders',
        'YourMasterTemplatesDefaultText': 'For faster work: create your own email templates and use them whenever you want — it’s simple!',
        'YourFoldersDefaultText': 'Save your file, never lose it!',
        'ImagesInThisFolder': 'images in this folder',
        'AddCustomAttributeButton': '+ Add custom attribute',
        'AttributeNameLabel': 'Attribute name',
        'AttributeValueLabel': 'Attribute value',
    },
    chartsTitle: {
        'ChartClientTitle': 'Clients',
        'ChartScenarioConversionTitle': 'Conversion rate',
        'ChartScenarioRevenueTitle': 'Scenario revenue',
        'ChartTemplateRevenueTitle': 'Template revenue',
        'ChartSendConversionTitle': 'Messages sent/Clients clicked',
        'ChartNumberOfPurchasesTitle': 'Completed purchases',
    },
    metrics: {
        'ScenarioMetricsTitle': 'Scenario metrics',
        'CommunicationsMetricsTitle': 'Communications metrics',
        'TemplateMetricsTitle': 'Template metrics',
        'MetricsScenarioEntered': 'Scenario entered',
        'MetricsCompletedPurchases': 'Completed purchases',
        'MetricsConversionRate': 'Conversion rate',
        'MetricsRevenue': 'Revenue',
        'MetricsSendMessages': 'Messages sent',
        'MetricsSiteConversion': 'Clients clicked',
        'MetricsABGroup': 'A/B group',
        'MetricsAllClients': 'Total customers in scenario',
        'MetricsEntered': 'Total customers in scenario',
        'MetricsMessage': 'Messages',
        'MetricsConversion': 'Conversion',
        'MetricsOpenedMessages': 'Messages opened',
        'MetricsConversionMessages': 'Messages conversion',
        'MetricsReceivedMessages': 'Messages received',
        'MetricsUnsubscribedClients': 'Unsubscribed customers',
        'MetricsTemplateRevenue': 'Template revenue',
        'MetricsClientMoved': 'Clients clicked',
        'MetricsThrown': 'Thrown',
    },
    metricsTable: {
        'TableColumnActionName': 'Action name',
        'TableColumnTemplateName': 'Template name',
        'TableColumnMsgSend': 'Sent',
        'TableColumnDelivered': 'Delivered',
        'TableColumnOpened': 'Opened',
        'TableColumnConversion': 'Conversion',
        'TableColumnUnsubscribed': 'Unsubscribed',
        'TableColumnRevenue': 'Purchase amount',
        'TableNoDataMessage': 'No data available',
        'TableColumnClicked': 'Clicked',
        'TableColumnPurchased': 'Purchased',
    },
    consentHeader: {
        'Markup': 'Markup',
        'Preview': 'Preview',
        'CancelChangesButton': 'Cancel changes',
        'SaveButton': 'Save'
    },
    popUpsSystemMessages: {
        'LogoutTitle': 'Confirm log out',
        'ErrorAlerterTitle': 'Whoops…. Something  went wrong 🧐',
        'ImageUploadAlerter': 'Check whether an image is less than 5Mb. Allowed formats .jpg, .gif, .png, .pictures',
        'FillableAlerterLabel': 'Alternative title',
        'FillableAlerterPlaceholder': '* Optional',
        'FillableAlerterButton': 'Upload image',
        'SaveScenariosTitle': 'Scenarios added',
        'SaveScenariosPreventUpdateTitle': 'Can`t save experiment without scenarios',
        'SaveScenariosPreventUpdateText': 'At least one launched scenario is required',
        'SaveScenariosConfirmUpdateText': 'Updated list of included scenarios',
        'DeleteScenarioTitle': 'Deleting scenario',
        'DeleteScenarioPreventText': 'Unable to delete scenario that is in use in an experiment. To delete scenario, it must be disabled.',
        'DeleteScenarioConfirmText': 'Are you sure you want to delete this scenario?',
        'CancelChangesScenarioTitle': 'Cancel scenario changes',
        'CancelChangesScenarioText': 'All changes are cancelled!',
        'ChangeScenarioNameTitle': 'Change scenario title',
        'ChangeScenarioNameText': 'Note! All scenario changes are enacted after the entire scenario has been saved!',
        'UpdateScenarioTitle': 'Save scenario',
        'UpdateScenarioText': 'Scenario is successfully saved!',
        'CloseEditScenarioTitle': 'Changes have been introduced in the current scenario!',
        'CloseEditScenarioText': 'Do you want to leave the editing page?',
        'SaveTemplateTitle': 'Save template',
        'SaveTemplateText': 'Template is successfully saved!',
        'ChangeTemplateNameTitle': 'Change template title',
        'ChangeTemplateNameText': 'Note! All template changes are enacted after the entire template has been saved!',
        'DeleteTemplateTitle': 'Deleting template',
        'DeleteTemplateText': 'Are you sure you want to delete this template?',
        'DeleteTemplatePreventText': 'Unable to delete template that is in use in an scenario. To delete template, it must be disabled.',
        'CopyTemplateTitle': 'Copying template',
        'CopyTemplateText': 'Template copy created successfully',
        'DefaultPopUpsTitle': 'Some title',
        'DefaultPopUpsText': 'Some text',
        'StartExperimentTitle': 'Experiment launch',
        'StartExperimentText': 'Your experiment start successfully',
        'SelectField': 'Select field',
        'WhereToUploadImage': 'Choose where to add the image',
        'DeleteRecommendationUnitTitle': 'Deleting recommendation unit',
        'DeleteRecommendationUnitText': 'Are you sure you want to delete this unit?',
        'ChangeUnitNameTitle': 'Change unit title',
        'ChangeUnitNameText': 'Note! All recommendation unit changes are enacted after the entire recommendation unit has been saved!',
        'UpdateUnitTitle': 'Save unit',
        'UpdateUnitText': 'Unit is successfully saved!',
        'DeleteAbTestGroupTitle': 'Deleting  A/B test group',
        'DeleteAbTestGroupText': 'Are you sure you want to delete this A/B test group?',
        'ConnectWebsiteBannerScript': 'Connect our script to website',
        'ConnectWebsiteBannerScriptMessage': 'Copy and paste the script link to your site, before the closing body tag on the home page.',
        'CancelChangesTemplateTitle': 'Cancel template changes',
        'CancelChangesTemplateText': 'Are you sure you want to cancel changes this template?',
        'CloseChangesTemplateTitle': 'Closing the template editor',
        'CloseChangesTemplateText': 'You have not saved your template changes. Close without saving?',
        'DeleteSegmentTitle': 'Deleting segment',
        'DeleteSegmentText': 'Are you sure you want to delete this segment?',
        'CopySegmentTitle': 'Copying segment',
        'CopySegmentText': 'Segment copy created successfully',
        'SaveSegmentTitle': 'Save segment',
        'SaveSegmentText': 'Segment saved successfully',
        'SaveEditSegmentTitle': 'Wait for the changes to take effect',
        'SaveEditSegmentText': 'When you save changes to a segment, it takes time to process the data. The process may take several minutes',
        'DeleteIntegrationEventTitle': 'Deleting event',
        'DeleteIntegrationEventText': 'Are you sure you want to delete this event?',
        'DeleteIntegrationFieldTitle': 'Deleting field',
        'DeleteIntegrationFieldText': 'Are you sure you want to delete this field?',
        'WarningTitle': 'Warning',
        'WarningSaveEventSchemaText': 'You are adding repeated or record fields to event schema. You won’t be able to upload csv data for such events',
        'DeletePolicyTitle': 'Deleting policy',
        'DeletePolicyText': 'Are you sure you want to delete this policy?',
        'UpdatedPolicyTitle': 'Updating policies',
        'UpdatedPolicyText': 'Policies is successfully saved!',
        'AddImageTitle': 'Add image',
        'CreateNewFolder': 'Create new folder',
        'CreateNewMaster': 'Create new master',
        'FolderName': 'Folder name',
        'MasterName': 'Email Master name',
        'UploadSelectedImages': 'Uploading images...',
        'Of': 'of',
        'Images': 'images',
        'DeleteModalTitle': 'Are you sure you want to delete?',
        'DeleteFolderLabel': 'Enter folder name to confirm',
        'DeleteMasterLabel': 'Enter master name to confirm',
        'InvalidOrEmptyFolderName': 'Invalid or empty folder name',
        'InvalidOrEmptyMasterName': 'Invalid or empty master name',
        'ImageUploadTechnicalMessage': 'All images larger than 5.24 MB will be automatically ignored. The maximum number of uploaded images is 10.',
        'InputRequiredField': 'Required field',
        'CreateEmailTemplateModalTitle': 'Create new template',
        'CreateEmailTemplateModalCodeEditorTitle': 'By Code Editor',
        'CreateEmailTemplateModalCodeEditorDesc': 'Use HTML and JINJA code',
        'CreateEmailTemplateModalMasterTitle': 'By Masters',
        'CreateEmailTemplateModalMasterDesc': 'Use our masters library',
        'SelectMasterTemplateModalTitle': 'Master’s library',
        'SelectMasterTemplateModalDesc': 'Use our template library',
        'CreateConsentModalInput1Label': 'Name *',
        'CreateConsentModalInput2Label': 'Description *',
    },
    validationErrorMessages: {
        'CheckboxValidationRequired': 'You must select at least one parameter',
        'InputValidationRequiredMessage': 'Required field',
        'SelectValidationRequiredMessage': 'Choose one option from the list',
        'PasswordValidationMessage': 'Password must be more than 8 characters',
        'EmailValidationMessage': 'Invalid email entered',
        'InputUnacceptableSymbolsValidationMessage': 'Invalid characters are present in the field',
        'PositiveNumberInputValidationMessage': 'The number must be greater than 0',
        'ZeroNumberInputValidationMessage': 'Zero is not a valid value. If you do not need this field, leave it empty',
        'UrlAddressInputValidationMessage': 'Please enter a valid URL',
        'EmailAddressInputValidationMessage': 'Please enter a valid E-mail address',
        'PhoneInputValidationMessage': 'The field must be between 7 and 14 characters',
        'InputTagsValuesOnlyNumericMessage': 'Only numeric values',
        'UserDoesNotExist': 'User does not exist',
        'Unauthorized': 'Invalid password',
        'TemplateValidationMessage': 'Template is required',
        'ChooseAtLeastOneOption': 'Choose at least one option',
        'SearchUserMessage': 'Select an attribute from the list'
    },
    actionArrows: {
        'positiveMiddleLabel': 'Yes',
        'negativeMiddleLabel': 'No'
    },
    websiteBanner: {
        'NameInputLabel': 'Name *',
        'EmailInputLabel': 'E-mail *',
        'PhoneInputLabel': 'Phone number *',
        'AgeInputLabel': 'Age *',
        'DefaultButtonTextLabel': 'Visit',
        'DefaultFeedbackTitleMessage': 'Please fill in all the fields below',
        'DefaultFeedbackThanksMessage': 'Thanks! We will contact you shortly!',
        'DefaultFeedbackButtonText': 'Send',
    },
    tableNoData: {
        'OopsLabel': 'Oops',
        'ScenarioListEmptyLabel': 'Scenario list is empty',
        'CreateScenarioLabel': 'Create scenario',
        'ConditionListEmptyLabel': 'There are no conditions',
        'CreateConditionLabel': 'Add new condition',
        'SegmentsListEmptyLabel': 'There are no segments',
        'CreateNewSegment': 'Add new segment',
        'ClientsSearchListLabel': 'Find customers to add to segment',
        'ClientsEmptyListLabel': 'There are no customers',
        'AddClientLabel': 'Add customers',
    },
    default: {
        'EnglishLang': 'English',
        'RussianLang': 'Русский (Украина)',
        'UkrainianLang': 'Українська',
        'PolishLang': 'Polski',
        'LoadingMessage': 'Loading',
        'SaveButtonTitle': 'Save',
        'CancelButtonTitle': 'Cancel',
        'SkipButtonTitle': 'Skip',
        'BackButtonTitle': 'Go back',
        'AcceptButtonTitle': 'Accept',
        'ConfirmButtonTitle': 'Confirm',
        'YesButtonTitle': 'Yes',
        'NoButtonTitle': 'No',
        'CreatedButtonTitle': 'Create',
        'ChoiceButtonTitle': 'Choice',
        'DateInputViewerLabel': 'Launch date *',
        'EditButtonTitle': 'Edit',
        'CollapseAllButtonTitle': 'Collapse all',
        'DeleteButtonTitle': 'Remove',
        'ReturnButtonTitle': 'Back',
        'CopyButtonTitle': 'Copy',
        'LaunchButtonTitle': 'Start',
        'SendButtonTitle': 'Send',
        'StatisticButtonTitle': 'Statistic',
        'ActionErrorTitle': 'Error!',
        'ActionSaveErrorMessage': 'It is necessary to fill in all the required fields in the settings of this Action.',
        'MondayShortcut': 'Mon',
        'TuesdayShortcut': 'Tue',
        'WednesdayShortcut': 'Wed',
        'ThursdayShortcut': 'Thu',
        'FridayShortcut': 'Fri',
        'SaturdayShortcut': 'Sat',
        'SundayShortcut': 'Sun',
        'MonthJanuary': 'January',
        'MonthFebruary': 'February',
        'MonthMarch': 'March',
        'MonthApril': 'April',
        'MonthMay': 'May',
        'MonthJune': 'June',
        'MonthJuly': 'July',
        'MonthAugust': 'August',
        'MonthSeptember': 'September',
        'MonthOctober': 'October',
        'MonthNovember': 'November',
        'MonthDecember': 'December',
        'UploadButtonTitle': 'Upload',
        'ActionTemplates': 'Action templates',
        'ChromeImage': 'Image for Chrome',
        'WebPushLogo': 'Web push logo',
        'FirstButton': 'First button',
        'SecondButton': 'Second button',
        'ActionTemplatePages': 'Templates list',
        'Of': 'of',
        'Close': 'Close',
        'AddButtonLabel': 'Create New',
        'UploadFromPc': 'From the computer',
        'AddNew': 'Add new',
        'BackToTemplatesButton': 'Back to Templates',
        'DeleteFolderButton': 'Delete folder',
        'DeleteButton': 'Delete',
        'ApplyAndEditButton': 'Apply and edit',
        'DuplicateAndEdit': 'Duplicate and edit',
        'LaunchExperimentButton': 'Launch experiment'
    },
    unitStatuses: {
        'AllStatuses': 'All',
        'DRAFT': 'Draft',
        'READY': 'Ready',
        'STOPPED': 'Inactive',
        'ACTIVE': 'Active',
        'LEARNING': 'Learning',
    },
    recommendationUnit: {
        'UnitStatus': 'Unit status',
        'RecommendationUnits': 'Recommendation units',
        'Subject':'Subject',
        'SubjectRecommendation': 'Subject of recommendation',
        'SelectSubjectRecommendation':'Select the subject of recommendation',
        'SelectRecommendationType':'Select the recommendation type',
        'ButtonAddRecommendationSlot': 'Add recommendation slot',
        'Cells': 'Cells',
        'Slot': 'Slot',
        'AbTestGroup': 'A/B test group',
        'TotalNumberCells': 'Total number of cells',
        'EmptySettings':'Empty settings',
        'CollaborativeFilteringAndDescriptionOfViewed':'Collaborative filtering and description of viewed',
        'CollaborativeFilteringBasedOnViews':'Collaborative filtering based on views',
        'ByFilling':'By filling',
        'Empty': 'Empty',
        'CreateNew': 'Create new',
        'PreviouslyUsed':'Previously used',
        'ByClient':'By customers',
        'Static':'Static',
        'Personal':'Personal',
        'SelectMetaType':'Select meta type',
        'MostPopularGoods':'Most popular goods',
        'NextGoodsToBuy':'Next goods to buy',
        'CrossSell': 'Cross sell',
        'AccessoriesForSelectedGoods': 'Accessories for selected goods',
        'NewType': 'New type',
        'AddConfigurationBlock': 'Add configuration block',
        'LearningTime': 'Learning time',
        'Ranker': 'Ranker',
        'Order':'Order'
    },
    subjectRecommendationType: {
        'PRODUCT': 'Product',
        'STOCK': 'Stock',
        'SET': 'Set',
        'ARTICLE': 'Article',
    },
    recommendationMetrics: {
        'Group': 'Group',
        'Conversion': 'Conversion',
        'ClientConversion': 'Client conversion',
        'DirectConversion': 'Direct conversion',
        'DirectClientConversion': 'Direct customer conversion',
        'CTR': 'CTR',
        'ClientCTR': 'Client CTR',
        'AOV': 'AOV',
        'GroupName': 'Name group',
        'unitAllTime': 'Unit for all time',
        'ABGroup': 'A/B group',
        'ABGroupsAllTime': 'A/B groups for all time',
        'ABGroupsForPeriod': 'A/B groups for period',
        'TableNoDataMessage': 'No data available',
        'LeadingClientConversionRate': 'Leading customer conversion rate',
        'Significance': 'Significance',
        'NotSignificant': 'Not significant',
        'LeadingDirectClientConversionRate': 'Leading direct customer conversion rate',
        'Uplift': 'Uplift',
        'DirectUplift': 'Direct uplift',
        'EmptyMetricsForDateRange': 'Empty metrics for the date range'

    },
    tableRecommendationGroup: {
        'NoName': '',
        'RecommendationSlot': 'Recommendation slot',
        'Cells': 'Cells',
        'Actions': 'Actions',
        'NoSlots': 'No slots',
        'RecommendationType': 'Recommendation type',
        'NumericSymbol': '№',
        'ConfigurationBlocks': 'Configuration blocks',
        'NoRecommendationType': 'No recommendation type'
    },
    segments: {
        'NewSegment': 'New Segment',
        'TypeSegments': 'Segment types',
        'StandardSegmentDescription': 'Customizing with segmentation conditions',
        'ManualSegmentDescription': 'Setting by customer attributes search',
        'GoThroughTutorial': 'Go through the tutorial',
        'GoThroughTutorialDescription': 'You will learn how to create a segment'
    },
    comparisonSegments: {
        'Segment': 'Segment',
        'Segments': 'Segments',
        'Clients': 'Clients',
        'Add': 'Add',
        'Conditions': 'Conditions',
        'Comparison': 'Comparison',
        'TotalClients': 'Total customers:',
        'ARPU': 'ARPU:',
        'AverageCheck': 'Average check:',
        'FrequencyPurchasesYear': 'Frequency of purchases per year:',
        'FrequencyPurchases30Days': 'Frequency of purchases per 30 days:',
        'ChurnRate': 'Churn rate:',
        'AverageValueSegments': 'Average value segments',
        'ValueCurrentSegment': 'Value current segment',
        'AverageCheckYear': 'Average check per year:',
        'TotalARPUYear': 'Total ARPU Year:',
        'TotalClientsYear': 'Total customers per year:',
    },
    conditionSegments: {
        'IncludeToSegment': 'Include to segment',
        'ExcludeFromSegment': 'Exclude from segment',
        'Include': 'Include',
        'Exclude': 'Exclude',
        'Add': 'Add',
        'SeparateAnd': 'And',
        'SeparateOr': 'Or'
    },
    clientSegment: {
        'PhoneNumber': 'Phone number:',
        'Email': 'Email:',
        'LoyalCardNumber': 'Loyal card number:',
        'UniqueUserId': 'Unique user Id:',
        'UserName': 'Name:',
        'LastUpdated': 'Last updated: ',
        'CommunicationChannels': 'Activities of other communication channels',
        'MainTabButton': 'Main',
        'AdditionalTabButton': 'Additional',
        'Birthday': 'Birthday',
        'Gender': 'Gender',
        'UserId': 'User id',
        'DeviceId': 'Device id',
        'ClientId': 'Customer id'
    },
    createScenarioSideBar: {
        'TitleNewScenario': 'New scenario',
        'TypeScenario': 'Scenario type',
        'EmptyScenarioLabel': 'Empty scenario',
        'EmptyScenarioDescription': 'Without previously added settings',
        'LibraryScenario': 'Library scenario'
    },
    fileDropzone: {
        'UploadCsvFile': 'Upload .csv',
        'UploadJsonFile': 'Upload .json',
        'DropFileHereLabel': 'Drop the file here',
        'Bytes': 'bytes',
        'DragAndDrop': 'Drag and drop',
        'YourImage': 'Your image',
    },
    integrationsTable: {
        'TableColumnEventName': 'Event name',
        'TableColumnFieldName': 'Field Name',
        'TableColumnType': 'Type',
        'TableColumnIsRequired': 'Is required',
        'TableColumnActions': 'Actions',
        'TableColumnIsInternal': 'Is internal',
        'TableColumnTopic': 'Topic',
    },
    integrations: {
        'UploadOrdersLabel': 'Upload orders',
        'UploadOrderItemsLabel': 'Upload order items',
        'UploadCustomersLabel': 'Upload customers',
        'UploadProductsLabel': 'Upload products',
        'ViewSchemaButtonLabel': 'View schema',
        'FieldNameLabel': 'Field name *',
        'TypeLabel': 'Type *',
        'UploadCsvHeaderTitle': 'Upload CSV',
        'LeftButtonUploadCsvHeaderTitle': 'Start upload',
        'RightButtonUploadCsvHeaderTitle': 'Go back',
        'CreateDataMappingForEvent': 'Create data mapping for event',
        'CsvUploadTuningSectionNotice': '* Make sure the loaded .csv table includes column names in the first row',
        'SetupCsvUploadNotice': '** The first 20 rows are displayed as an example only. All data will be downloaded',
        'CsvTableDropBoxLabel': 'Drop here',
        'UploadCsvProcessTitle': 'Uploading csv table',
        'ApiFailureUploadCsvProcessTitle': 'Upload error. Please try again',
        'IngestionProcessLabel': 'Ingestion process',
        'SuccessfullyUploadMessageFirstPart': 'Successfully ingested',
        'SuccessfullyUploadMessageSecondPart': 'events from csv file',
        'IntegrationsPageTitle': 'Integrations',
        'SeeAllEventsButton': 'See all events',
        'GoBackToUploadButton': 'Go back to upload',
        'AddNewEventSidebarTitle': 'Add new event',
        'RequiredFieldLabel': 'Required field'
    },
    frequencyPolicies: {
        'NewPolicyTitle': 'New Policy',
        'PolicyNameLabel': 'Name *',
        'PolicyMaximumCommunicationsLabel': 'Maximum Communications *',
        'PolicyFrequencyLabel': 'Frequency *',
        'FieldName': 'Field Name',
        'MaximumCommunications': 'Maximum communications',
        'Frequency': 'Frequency',
        'ScenariosWithPolicy': 'Scenarios with policy',
        'Actions': 'Actions',
        'CommunicationFrequencyTitle': 'Communication frequency policies',
    },
    mainPage: {
        'Welcome': 'Welcome',
        'WelcomeDesc': 'Understand your customers’ life cycle using NeuCurrent customer retention analytics',
        'MainPageTitle': 'Your customer base analysis',
        'MainPageDesc': 'We will run your data through our analytical engine, calculate retention metrics, understand your current issues and offer solutions',
        'Circle1Desc': 'Understand life-time value of your customers',
        'Circle2Title': 'Customer dynamics',
        'Circle2Desc': 'Understand your customer life cycle',
        'Circle3Title': 'Revenue',
        'Circle3Desc': 'Understand main revenue drivers',
        'MainPageSubTitle': 'Customer retention strategy',
        'MainPageSubDesc': 'By analyzing your data we will identify most critical issues and form a retention strategy to solve them',
        'Critical': 'Critical',
        'Risk': 'Risk',
        'Opportunity': 'Opportunity',
    },
    mainPageDashboard: {
        'Welcome': 'Your individual retention strategy',
        'WelcomeDesc': 'Examine your retention issues and start customer engagements',
        'RetentionStrategy': 'Retention Strategy',
        'StartWithLabel': 'Start with',
        'isNotReadySectionMessage': 'By analyzing your data we will identify most critical issues and create a retention strategy to solve them',
        'isReadySectionMessage': 'By analyzing your historic transactional data we detect customer retention problems which significantly influence your revenue',
        'Critical': 'Critical',
        'Risk': 'Risk',
        'Opportunity': 'Opportunity',
        'DropdownSectionCriticalDesc': 'Issues that have a significant impact on sales. Require your immediate attention.',
        'DropdownSectionRiskDesc': 'Potential critical issues. Require your attention to prevent developing into significant negative trends.',
        'DropdownSectionOpportunityDesc': 'Opportunities for future sales growth.',
        'DropdownCardInterval': 'Monthly',
        'DropdownCardStatusNew': 'New',
        'DropdownCardStatusScenarioLaunched': 'Scenario launched, waiting for results',
        'DropdownCardStatusFirstResults': 'We have first results',
        'DropdownCardStatusResolved': 'Resolved',
        'DropdownCardStatusMetricsImproved': 'Metrics improved',
        'DropdownCardStatusMetricsDeteriorated': 'Metrics deteriorated',
        'LtvDesc': 'Revenue each customer generates',
        'LtvCard1': 'Life span in years',
        'LtvCard2': 'Purchase frequency per year',
        'RevenueTitle': 'Revenue',
        'RevenueDesc': 'Your main revenue drivers over the last 30 days',
        'RevenueCard1': 'Active customers in the last 30 days',
        'RevenueCard2': 'Period frequency',
        'CustomerDynamicsTitle': 'Customers base',
        'PopUpCardTitle': 'Metric description',
        'CustomerDynamicsElem3': 'Single purchase customers',
        'CustomerDynamicsElem6': 'New customers',
        'CustomerDynamicsElem8': 'Activated',
        'CustomerDynamicsElem10': 'Risk of churn',
        'CustomerDynamicsElem14': 'Subscriptions',
        'CustomerDynamicsElem16': 'Churned',
        'CustomerDynamicsElem3Message': 'Single purchase customers',
        'CustomerDynamicsElemDays': 'days',
        'CustomerDynamicsElemDaysAgo': 'days ago',
        'CustomerDynamicsElemDaysBefore': 'days before',
        'CustomerDynamicsElem3Message1': 'Has 1 purchase and purchase was done more than',
        'CustomerDynamicsElem3Message2': 'days ago but less than',
        'CustomerDynamicsElem6Message1': 'Has 1 purchase and purchase was done less than or equal',
        'CustomerDynamicsElem8Message1': 'Has 2 or more purchases and last purchase was done less than',
        'CustomerDynamicsElem10Message1': 'Has at least one purchase that was done more or equal',
        'CustomerDynamicsElem10Message2': 'days and less than',
        'CustomerDynamicsElem14Message1': 'Has at least one direct communication channel and no purchases',
        'CustomerDynamicsElem16Message1': 'Has at least one purchase that was done more or equal than',
        'CustomerDynamicsElem1Message1': 'New customers over the last',
        'CustomerDynamicsElem2Message1': 'Single over the last',
        'CustomerDynamicsElem2Message2': 'New',
        'CustomerDynamicsElem4Message1': 'Risk of churn from single over the last',
        'CustomerDynamicsElem4Message2': 'Single',
        'CustomerDynamicsElem5Message1': 'Active from single over the last',
        'CustomerDynamicsElem5Message2': 'New',
        'CustomerDynamicsElem7Message1': 'Active from new over the last',
        'CustomerDynamicsElem7Message2': 'New during the last',
        'CustomerDynamicsElem9Message1': 'Risk of churn from Active over the last',
        'CustomerDynamicsElem9Message2': 'Active',
        'CustomerDynamicsElem11Message1': 'New, Single, Active from subscribers over the last',
        'CustomerDynamicsElem11Message2': 'Subscribers',
        'CustomerDynamicsElem12Message1': 'Active from risk of churn over the last',
        'CustomerDynamicsElem12Message2': 'Risk of churn',
        'CustomerDynamicsElem13Message1': 'Churned from the risk of churn over the last',
        'CustomerDynamicsElem13Message2': 'Risk of churn',
        'CustomerDynamicsElem15Message1': 'Active from churn over the last',
        'CustomerDynamicsElem15Message2': 'Churn',
        'CustomerDynamicsElem17Message1': 'New subscribers over the last',
    },
    calendar: {
        'AllTimeButton': 'All time',
        'ThisYearButton': 'This year',
        'ThisMonthButton': 'This month',
        'ThisWeekButton': 'This week',
        'ClearButton': 'Clear',
        'AppleButton': 'Apply',
        'Selected': 'Selected',
        'Days': 'days'
    },
    clientDashboardPage: {
        'InformationAboutClientTitle': 'Customer information',
        'ConsentsTitle': 'Customer consent',
        'ClientKpiTitle': 'Customer status',
        'TableOfEvents': 'Table of events',
        'FilterDropdown': 'Filter',
        'SortDropdown': 'Sort',
        'PeriodButton': 'Period:',
        'PeriodButtonAllTimeLabel': 'All time',
        'ActivityStatusLabel': 'Activity status',
        'SubscriptionDateLabel': 'Subscription date',
        'OrderDateLabel': 'Order date',
        'FirstLabel': 'First',
        'LastLabel': 'Last',
        'Order12MonthFrequencyStatusLabel': 'Orders 12 month frequency status',
        'OrdersCurrentMonthStatusLabel': 'Orders current month status',
        'CustomerMainOrderSourceLabel': 'Customer main order source',
        'CustomerMainPosLabel': 'Customer main POS',
        'OrderMetricsTitle': 'Order metrics',
        'OverallColumn': 'Overall',
        'Last12MonthColumn': 'Last 12 month',
        'CurrentPeriodColumn': 'Current Period',
        'OrderCountRow': 'Orders count',
        'OrdersSumRow': 'Orders sum',
        'AovRow': 'AOV',
        'OrdersProductsQuantityRow': 'Orders products quantity',
        'CommunicationChannelActive': 'Customer moved through the channel at least once in the last 3 months',
        'CommunicationChannelNotActive': 'Communications sent to this channel, but customer did not move through the channel',
        'CommunicationChannelUndefined': 'The status of the channel is not known (no communications delivered to this channel in the last 12 months)',
        'CommunicationChannelFailed': 'Failure to deliver communications on this channel',
        'CommunicationChannelNotAvailable': 'This attribute is not available for a customer',
        'TooltipExternalEvents': 'The events that are triggered by customer activity outside of the NeuCurrent platform. Platform users can also add their customised events to the platform. All other non-categorised events are External',
        'TooltipWebsiteActivity': 'Customer activity on the website',
        'TooltipMobileAppActivity': 'Customer activity on the app',
        'TooltipCommunications': 'The events relating to customer communications',
        'TooltipPlatformEvents': 'The events that are triggered within the platform'
    },
    iconicDropdown: {
        'Newest': 'Newest',
        'Oldest': 'Oldest'
    },
    templatesSetupAside: {
        'MasterAsideTitle': 'Your Master Setup',
        'MasterAsideDesc': 'Setup your own master template, fill up all fields below',
        'ImagesAsideTitle': 'Your Images',
        'ImagesAsideDesc': 'Select, upload, and delete your images in folders below. Click on image to copy url path to clipboard.',
        'AsideImagesFoldersTitle': 'Upload your own images',
        'SettingsAsideTitle': 'Template settings',
        'SettingsAsideDesc': 'Complete all fields below to setup your template',
        'TestAsideTitle': 'Test your template',
        'TestAsideDesc': 'Complete all fields below to test your template'
    },
    policies: {
        'PoliciesLabel': 'Policies',
        'PoliciesDesc': 'Set communication frequencies and consent requirements for your customers',
        'FrequenciesButtonTitle': 'Frequencies',
        'FrequenciesButtonDesc': 'How often do you want to send communications to your customers?',
        'ConsentsButtonTitle': 'Consents',
        'ConsentsButtonDesc': 'Set consent requirements for your customers',
    },
    metricViewPage: {
        'LaunchExperimentButton': 'Launch experiment',
        'ProblemsListSectionTitle': 'Problems that involve this metric',
        'PastYearLabel': 'Past year',
        'CurrentYearLabel': 'Current year',
        'DetectedProblemLabel': 'Detected problem',
    }
};

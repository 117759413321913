import React, { useEffect, useRef, useState } from 'react';
import { eq, split, head } from 'lodash';
import classNames from 'classnames/bind';
import styles from 'components/MainHeader/MainHeader.module.css';
import animations from 'animations/FadeIn.module.css';
import britishFlag from 'assets/img/BritishFlag.svg';
import ukrainianFlag from 'assets/img/UkrainianFlag.svg';
import polishFlag from 'assets/img/PolishFlag.svg';
import arrow from 'assets/img/SelectedArrowBlue.svg';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';

const cx = classNames.bind(styles);

const langChecker = lang => {
    console.log('langChecker', lang);
    console.log('langChecker parsed value', head(split(lang, '-')));

    switch (head(split(lang, '-'))) {
        case 'ua':
            return { lang: 'ua', flag: ukrainianFlag };
        case 'ru':
            return { lang: 'ru', flag: ukrainianFlag };
        case 'pl':
            return { lang: 'pl', flag: polishFlag };
        default:
            return { lang: 'en', flag: britishFlag }
    }
};

export const SelectLanguageDropdown = () => {
    const { t: translator, i18n } = useTranslation();

    const [showSelectLangContainer, setShowSelectLangContainer] = useState(false);
    const [localization, setLocalization] = useState(langChecker(i18n.language));

    const wrappedRef = useRef(null);

    const changeLanguage = lng => {
        return () => i18n.changeLanguage(lng);
    };

    const showSelectLangContainerHandler = () => setShowSelectLangContainer(prev => !prev);

    useEffect(() => {
        setLocalization(langChecker(i18n.language));
        console.log('useEffect langChecker(i18n.language)', langChecker(i18n.language));
        setShowSelectLangContainer(false);
    }, [i18n.language]);

    useOutsideAlerter(wrappedRef, () => setShowSelectLangContainer(false));

    return (
        <div className={cx(styles.selectLanguageContainer)} ref={wrappedRef}>
            <div
                className={cx(styles.selectedFlagContainer)}
                onClick={showSelectLangContainerHandler}
            >
                <img src={localization?.flag} alt='selectedFlag' className={cx(animations.fadeIn200ms)}/>
            </div>

            {showSelectLangContainer &&
                <div className={cx(styles.selectLanguageDropdown, animations.fadeIn200ms)}>
                    <div
                        className={cx(styles.selectLanguageDropdownItem, { 'selected': eq(localization.lang, 'en') } )}
                        onClick={changeLanguage('en')}
                    >
                        <img src={arrow} alt='arrow' className={cx(styles.selectedArrow, { 'show': eq(localization.lang, 'en') } )}/>
                        <img src={britishFlag} alt='britishFlag'/>
                        <p>{translator('default:EnglishLang')}</p>
                    </div>
                    <div
                        className={cx(styles.selectLanguageDropdownItem, { 'selected': eq(localization.lang, 'ua') } )}
                        onClick={changeLanguage('ua')}
                    >
                        <img src={arrow} alt='arrow' className={cx(styles.selectedArrow, { 'show': eq(localization.lang, 'ua') } )}/>
                        <img src={ukrainianFlag} alt='ukrainianFlag'/>
                        <p>{translator('default:UkrainianLang')}</p>
                    </div>
                    <div
                        className={cx(styles.selectLanguageDropdownItem, { 'selected': eq(localization.lang, 'ru') } )}
                        onClick={changeLanguage('ru')}
                    >
                        <img src={arrow} alt='arrow' className={cx(styles.selectedArrow, { 'show': eq(localization.lang, 'ru') } )}/>
                        <img src={ukrainianFlag} alt='ukrainianFlag'/>
                        <p>{translator('default:RussianLang')}</p>
                    </div>
                    <div
                        className={cx(styles.selectLanguageDropdownItem, { 'selected': eq(localization.lang, 'pl') } )}
                        onClick={changeLanguage('pl')}
                    >
                        <img src={arrow} alt='arrow' className={cx(styles.selectedArrow, { 'show': eq(localization.lang, 'pl') } )}/>
                        <img src={polishFlag} alt='polishFlag'/>
                        <p>{translator('default:PolishLang')}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export const russianTranslation = {
    auth: {
        // Auth login
        'GoogleAuthMessage': 'Вход через учетную запись Google',
        'Login': 'Логин',
        'Password': 'Пароль',
        'RegistrationLink': 'Регистрация',
        'InputValidationRequiredMessage': 'Поле обязательно для ввода',
        'BrowserListMessage': 'Используйте один из веб-браузеров из этого списка:',
        // Auth registration
        'RegistrationAnnotationMessage': 'Предоставьте вашу почту и краткое описание. Мы свяжемся с вами!',
        'EmailAddress': 'Адрес электронной почты',
        'UserRegistrationMessage': 'Описание',
        'LoginLink': 'Вход',
        'AcceptButton': 'Далее',
        // Auth thank you pop-up
        'ThankYouPopUpMessage': 'Спасибо, мы с вами скоро свяжемся!',
        'ReturnButton': 'Вернуться',
    },
    mainLeftSideBar: {
        'Retention': 'Удержание',
        'Campaigns': 'Кампании',
        'Templates': 'Шаблоны',
        'Integrations': 'Интеграции',
        'Recommendations': 'Рекомендации',
        'Audiences': 'Аудитории',
        'Analytics': 'Аналитика',
        'Instructions': 'Инструкции',
        'Settings': 'Настройки'
    },
    mainHeader: {
        'LaunchScenarioMessage': 'Готовы привлечь своих клиентов?',
        'LaunchScenarioButtonName': 'Запуск сценария'
    },
    mainHeaderBreadcrumbs: {
        'home': 'Главная',
        'dashboard': 'Доска',
        'campaigns': 'Кампании',
        'goal': 'Цель',
        'conversion': 'Конверсия',
        'cross-cell': 'Перекрестная продажа',
        'retention': 'Напоминание',
        'all-scenarios': 'Все сценарии',
        'metrics': 'Метрики',
        'custom-webhook': 'Request Body',
        'templates': 'Шаблоны',
        'edit-template': 'Редактор шаблона',
        'all-templates': 'Все шаблоны',
        'email': 'Email',
        'viber': 'Viber',
        'sms': 'Sms',
        'consent': 'Согласия',
        'markup': 'Разметка',
        'recommendations': 'Рекомендации',
        'units': 'Юниты',
        'type': 'Тип',
        'webpushnotify': 'Веб пуши',
        'mobpushnotify': 'Мобильные пуши',
        'website': 'Вебсайт баннеры',
        'folder': 'Папка',
        'audiences': 'Аудитории',
        'analytics': 'Аналитика',
        'client': 'Клиент',
        'integrations': 'Интеграции',
        'allEvents': 'Все события',
        'addNewField': 'Добавить поле',
        'uploadOrders': 'Загрузка заказов',
        'uploadOrderItems': 'Загрузка позиции товаров',
        'uploadCustomers': 'Загрузка продуктов',
        'uploadProducts': 'Загрузка продуктов',
        'communicationFrequencyPolicies': 'Настройка частоты коммуникаций',
        'communicationConsents': 'Согласия',
        'master': 'Мастер',
        'masterBased': 'Основан на мастере',
        'billing': 'Цены',
        'processing': 'Установка',
        'settings': 'Настройки'
    },
    searchInput: {
        'SearchInputPlaceholder': 'Поиск по названию',
        'SearchSegmentPlaceholder': 'Поиск сегмента',
        'SearchClientPlaceholder': 'Поиск клиентов',
        'SearchEventNamePlaceholder': 'Поиск события',
        'SearchPlaceholder': 'Поиск',
        'SearchProductPlaceholder': 'Поиск продукта по названию или артикулу',
    },
    filter: {
        'ScenariosStatusTitle': 'Статус сценария',
        'TemplateStatusTitle': 'Статус шаблона',
        'TemplateToolTitle': 'Инструмент шаблона',
        'SelectWebsiteBannerInputs': 'Доступные типы полей',
        'AllButton': 'Все',
        'ClearButton': 'Очистить',
        'Active': 'Активно',
        'Stopped': 'Остановаленные',
        'Ready': 'Готовый',
        'Draft': 'Черновик',
        'Name': 'Имя',
        'Email': 'Почта',
        'Age': 'Возраст',
        'Phone': 'Номер телефона',
        'ClientEvents': 'Client events',
        'ProductEvents': 'Product events',
        'Yes': 'Да',
        'No': 'Нет',
        'TopicFilter': 'Тема',
        'InternalFilter': 'Внутренний',
        'TemplatesTypeFilter': 'Тип',
        'TemplatesLanguageFilter': 'Язык',
        'CrossSell': 'Перекрестная продажа',
        'WebsiteConversion': 'Конверсия сайта',
        'TransactionalMessages': 'Транзакционные сообщения',
        'PersonalisedDigests': 'Персонализированные дайджесты',
        'MastersTemplateFilter': 'Мастеры',
        'CodeEditorTemplateFilter': 'Редактор кода',
    },
    scenariosTable: {
        'TableColumnName': 'Имя',
        'TableColumnLastChange': 'Последние изменения',
        'TableColumnMethods': 'Действия',
        'ConversionNoScenariosMessage': 'Сценарии конверсии отсутствуют. Создайте свой первый сценарий.',
        'CrossSellNoScenariosMessage': 'Сценарии перекрестных продаж отсутствуют. Создайте свой первый сценарий.',
        'RetentionNoScenariosMessage': 'Сценарии напоминаний отсутствуют. Создайте свой первый сценарий.',
        'AllScenariosNoScenariosMessage': 'Сценарии отсутствуют. Создайте свой первый сценарий.'
    },
    segmentsTable: {
        'TableColumnName': 'Имя',
        'TableColumnLastChange': 'Последние изменения',
        'TableColumnMethods': 'Действия',
        'TableColumnAmountClient': 'Количество клиентов',
        'TableColumnEmail': 'Емеил',
        'TableColumnTelephone': 'Телефон',
        'TableColumnUserId': 'Id пользователя',
        'TableColumnEventType': 'Тип',
        'TableColumnEvent': 'Событие',
        'TableColumnDate': 'Дата',
        'TableColumnTime': 'Время',
        'NoName': '',
        'NoRecordToDisplay': 'Нет данных для отображения'
    },
    scenarioStatuses: {
        'Draft': 'Черновик',
        'Ready': 'Готов',
        'Stopped': 'Остановленный',
        'Active': 'Активный',
        'Library': 'Библиотечный сценарий'
    },
    templateStatuses: {
        'Draft': 'Черновик',
        'Ready': 'Готов',
    },
    dashboardHeader: {
        'RecommendationUnitStatusTitle': 'Статус юнита:',
        'ScenariosStatusTitle': 'Статус сценария:',
        'TemplateStatusTitle': 'Статус шаблона:',
        'DesignNavLink': 'Дизайн',
        'MetricsNavLink': 'Метрики',
        'RequestBodyNavLink': 'Request Body',
        'SqlTriggerNavLink': 'SQL Запрос',
        'CancelChangesButton': 'Отменить изменения',
        'SaveChangesButton': 'Сохранить',
        'TestButton': 'Тест',
    },
    actionSidebars: {
        'actionName': 'Название события *',
        'actionSubName': 'Подназвание',
        'maxAudienceSize': 'Максимальный размер аудитории',
        'NewAttributeFilterButton': '+ Новый фильтр по атрибутам',
        'NewAttributeFilterName': 'Фильтр событий',
        'EventAttributeNameLabel': 'Название атрибута события *',
        'ComparisonOperatorLabel': 'Оператор сравнения *',
        'ComparisonValueLabel': 'Значение для сравнения',
        'TimeFieldLabel': 'Местное время',
        'NewConditionButton': '+ Создать новое условие',
        'NewConditionName': 'Условие',
        'SegmentationFilterLabel': 'Фильтр по сегментации',
        'SegmentationFilterEvent': 'Событие',
        'SegmentationFilterAttribute': 'Атрибуты',
        'SegmentationFilterMetric': 'Метрики',
        'countDays': 'Количество дней *',
        'countHours': 'Количество часов *',
        'LastTimeIntervalLabel': 'За последнее время',
        'CurrentTimeIntervalLabel': 'Определенный период времени',
        'AtScriptStartName': 'С момента запуска сценария',
        'MetricAttributeNameLabel': 'Название метрики *',
        'ClientAttributeNameLabel': 'Название атрибута клиента *',
        'AggregationFunctionNameLabel': 'Функция агрегирования',
        'AggregationColumnNameLabel': 'Колонка агрегирования *',
        'waitInterval': 'Количество минут *',
        'waitToDate': 'Метрика ожидания (дней)',
        'dateOffset': 'Смещение дней',
        'waitToTime': 'Метрика ожидания часов',
        'timeOffset': 'Смещение времени',
        'controlGroup': 'Контрольная группа:',
        'aGroupValue': 'A:',
        'bGroupValue': 'Б:',
        'shareCount': 'Доля, которая будет отправляться *',
        'countClients': 'Максимальное количество клиентов для отправки *',
        'countTimeIntervalNumber': 'На протяжении *',
        'evalTimeInterval': 'Отправлять равномерно',
        'CountFunnelTabButton': 'Количество',
        'ShareFunnelTabButton': 'Доля',
        'RepeatTabButton': 'Repeat',
        'ScheduleTabButton': 'Schedule',
        'minutesTimeInterval': 'Минут',
        'hoursTimeInterval': 'Часов',
        'daysTimeInterval': 'Дней',
        'weeksTimeInterval': 'Недель',
        'monthTimeInterval': 'Месяцев',
        'EventConditionSplitTabButton': 'Событие',
        'ClientConditionSplitTabButton': 'Клиенту',
        'ProductConditionSplitTabButton': 'Товару',
        'NewProductFilterButton': '+ Новый фильтр по товару',
        'NewProductFilterName': 'Фильтр товара',
        'ColumnNameOfProductFromEventLabel': 'ID товара из события *',
        'ColumnNameFromEventLabel': 'Атрибут товара из события *',
        'ColumnNameFromCatalog': 'Атрибут товара из каталога *',
        'ReceiverContactEmailLabel': 'Email получателя',
        'ReceiverContactPhoneLabel': 'Номер телефона получателя',
        'emailMaxCount': 'Максимальное кол-во отправок клиенту *',
        'MinDispatchTime': 'Минимальное время отправки *',
        'MaxDispatchTime': 'Максимальное время отправки *',
        'NewJsonHeaderButton': '+ Новый Header',
        'impressionsNumber': 'Количество показов',
        'DisplayDateLabel': 'Крайняя дата отображения',
        'NewUrlButton': '+ Новый Url',
        'MaxShowing': 'Максимальное количество показов *',
        'RangeOfDatesInputLabel': 'Промежуток времени *',
        'templateCache': 'Кэшировать шаблон',
        'ConditionName': 'Название условия *',
        'Conditions': 'Условие',
        'Segments': 'Сегменты',
        'MadePurchaseSilmplifiedSettings': 'Совершил покупку',
        'AddToBasketSilmplifiedSettings': 'Добавил в корзину',
        'ViewedProductSilmplifiedSettings': 'Просмотрел товар',
        'ReadEmailSilmplifiedSettings': 'Читал имейл',
        'ReadViberSilmplifiedSettings': 'Читал viber',
        'ReadSmsSilmplifiedSettings': 'Читал sms',
        'TimeInterval': 'Временной промежуток *',
        'CommunicationPolicyLabel': 'Настройка частоты коммуникаций *'
    },
    templates: {
        'TabsTemplatesEmailTitle': 'Email шаблоны',
        'TabsTemplatesSmsTitle': 'SMS шаблоны',
        'TabsTemplatesViberTitle': 'Viber шаблоны',
        'TabsTemplatesConsentTitle': 'Страница согласия',
        'TabsTemplatesWebsiteBuilderTitle': 'Конструктор баннеров',
        'TabsTemplatesMobilePushTitle': 'Mobile Push шаблоны',
        'TabsTemplatesWebPushTitle': 'Web Push шаблоны',
        'SenderNameLabel': 'Имя отправителя *',
        'SenderPhoneLabel': 'Номер отправителя *',
        'SenderEmailLabel': 'Почта отправителя *',
        'SenderMsgTopic': 'Тема сообщения *',
        'ButtonTextLabel': 'Текст кнопки',
        'FrontButtonTextLabel': 'Текст кнопки лицевой стороны баннера *',
        'FrontButtonColorLabel': 'Цвет кнопки лицевой стороны баннера (hex) *',
        'BackButtonTextLabel': 'Текст кнопки обратной стороны баннера *',
        'BackButtonColorLabel': 'Цвет кнопки обратной стороны баннера (hex) *',
        'BackSideBannerTitleLabel': 'Заголовок формы обратной связи *',
        'BackSideBannerThanksLabel': 'Сообщение с благодарностью *',
        'BodyTextLabel': 'Сообщение *',
        'UrlButtonLabel': 'URL кнопки',
        'UrlButtonLabelRequired': 'URL кнопки *',
        'TextAreaLabel': 'Текст *',
        'UploadImageLabel': 'Изображение 200x400px',
        'UrlImageLabel': 'URL изображения или Jinja код',
        'DeepLinkUrlLabel': 'Глубокая ссылка',
        'UploadBannerLabel': 'Загрузите баннер *',
        'CommunicationProviderLabel': 'Провайдер коммуникации',
        'ConsentCategoryLabel': 'Consent категория',
        'TemplateSidebarTitle': 'Шаблон',
        'UtmTagsLabel': 'UTM-метки',
        'BannerPositionLabel': 'Рассположение баннера',
        'BannerSizeLabel': 'Размер баннера',
        'TitleLabel': 'Заголовок *',
        'TextLabel': 'Текст *',
        'LinkPushLabel': 'Ссылка для push *',
        'TextButtonLabel': 'Текст кнопки',
        'LinkLabel': 'Ссылка',
        'TtlLabel': 'Время показа',
        'TtlSeconds': '(секунды)',
        'UploadPictureLabel': 'Изображение *',
        'UploadLogoLabel': 'Логотип *',
        'UploadLogoSize': 'до 128 kB',
        'UploadChromeLogoLabel': 'Изображение для Google Chrome*',
        'UploadChromeLogoSize': 'до 5.24 MB',
        'EmailLabel': 'Почта *',
        'PhoneLabel': 'Телефон *',
        'TokenLabel': 'Токен *',
        'TitleSendTest': 'Отправить тест',
        'YourMasterTemplates': 'Ваши мастер шаблоны',
        'YourFolders': 'Ваши папки',
        'YourMasterTemplatesDefaultText': 'Для более быстрой работы: создавайте собственные шаблоны писем и используйте их в любое время — это просто!',
        'YourFoldersDefaultText': 'Сохраните файл, никогда его не теряйте!',
        'ImagesInThisFolder': 'изображения(-ий) в этой папке',
        'AddCustomAttributeButton': '+ Добавить пользовательский атрибут',
        'AttributeNameLabel': 'Имя атрибута',
        'AttributeValueLabel': 'Значение атрибута',
    },
    chartsTitle: {
        'ChartClientTitle': 'Клиенты',
        'ChartScenarioConversionTitle': 'Коэффициент конверсии',
        'ChartScenarioRevenueTitle': 'Доход сценария',
        'ChartTemplateRevenueTitle': 'Доход шаблона',
        'ChartSendConversionTitle': 'Отправлено/перешли на сайт',
        'ChartNumberOfPurchasesTitle': 'Количество покупок',
    },

    metrics: {
        'ScenarioMetricsTitle': 'Метрики сценария',
        'CommunicationsMetricsTitle': 'Метрики коммуникаций',
        'TemplateMetricsTitle': 'Метрики шаблона',
        'MetricsScenarioEntered': 'Вошли в сценарий',
        'MetricsCompletedPurchases': 'Совершили покупку',
        'MetricsConversionRate': 'Коэффициент конверсии',
        'MetricsRevenue': 'Доход',
        'MetricsSendMessages': 'Отправлено сообщений',
        'MetricsSiteConversion': 'Перешли на сайт',
        'MetricsABGroup': 'А/Б група',
        'MetricsAllClients': 'Всего клиентов в сценарии',
        'MetricsMessage': 'Сообщения',
        'MetricsConversion': 'Конверсии',
        'MetricsOpenedMessages': 'Открыто сообщений',
        'MetricsConversionMessages': 'Переходов из сообщения',
        'MetricsReceivedMessages': 'Доставлено сообщений',
        'MetricsUnsubscribedClients': 'Клиентов отписалось',
        'MetricsTemplateRevenue': 'Доход шаблона',
        'MetricsClientMoved': 'Клиентов перешли',
        'MetricsThrown': 'Брошено',
    },
    metricsTable: {
        'TableColumnActionName': 'Имя действия',
        'TableColumnTemplateName': 'Имя шаблона',
        'TableColumnMsgSend': 'Отправлено',
        'TableColumnDelivered': 'Доставлено',
        'TableColumnOpened': 'Открыто',
        'TableColumnConversion': 'Конверсия',
        'TableColumnUnsubscribed': 'Отписались',
        'TableColumnRevenue': 'Сумма покупок',
        'TableNoDataMessage': 'Данные отсутствуют',
        'TableColumnClicked': 'Перейшли',
        'TableColumnPurchased': 'Куплено',
    },

    consentHeader: {
        'Markup': 'Разметка',
        'Preview': 'Превью',
        'CancelChangesButton': 'Отменить изменения',
        'SaveButton': 'Сохранить'
    },
    popUpsSystemMessages: {
        'LogoutTitle': 'Подтвердите выход из аккаунта',
        'ErrorAlerterTitle': 'Упс.. что-то пошло не так 🧐 ',
        'ImageUploadAlerter': 'Убедитесь что загружаемая картинка меньше 5.00 Мегабайт \n и имеет допустимый формат .jpg, .gif, .png, .pictures',
        'FillableAlerterLabel': 'Альтернативное название картинки',
        'FillableAlerterPlaceholder': '* Не обязательное поле',
        'FillableAlerterButton': 'Загрузить изображение',
        'SaveScenariosTitle': 'Добавление сценариев',
        'SaveScenariosPreventUpdateTitle': 'Невозможно сохранить эксперимент без сценариев',
        'SaveScenariosPreventUpdateText': 'Необходимо иметь хотя бы один запущенный сценарий',
        'SaveScenariosConfirmUpdateText': 'Обновлен список включенных сценариев',
        'DeleteScenarioTitle': 'Удаление сценария',
        'DeleteScenarioPreventText': 'Невозможно удалить сценарий, который используется в эксперименте. Что бы удалить сценарий, его необходимо отключить.',
        'DeleteScenarioConfirmText': 'Вы уверены что хотите удалить данный сценарий?',
        'CancelChangesScenarioTitle': 'Отмена изменений сценария',
        'CancelChangesScenarioText': 'Все изменения успешно отменены!',
        'ChangeScenarioNameTitle': 'Изменение имени сценария',
        'ChangeScenarioNameText': 'Внимание! Изменения вступят в силу после полного сохранения сценария!',
        'UpdateScenarioTitle': 'Сохранение сценария',
        'UpdateScenarioText': 'Сценарий успешно сохранен в базе',
        'CloseEditScenarioTitle': 'В текущий сценарий были внесены изменения!',
        'CloseEditScenarioText': 'Вы хотите покинуть страницу редактирования сценария. Выйти без сохранения?',
        'SaveTemplateTitle': 'Сохранение шаблона',
        'SaveTemplateText': 'Шаблон успешно сохранен!',
        'ChangeTemplateNameTitle': 'Изменение имени шаблона',
        'ChangeTemplateNameText': 'Внимание! Измения вступят в силу после полного сохранения шаблона!',
        'DeleteTemplateTitle': 'Удаление шаблона',
        'DeleteTemplateText': 'Вы уверены что хотите удалить данный шаблон?',
        'DeleteTemplatePreventText': 'Невозможно удалить шаблон, который используется в сценарии. Что бы удалить шаблон, его необходимо отключить.',
        'CopyTemplateTitle': 'Копирование шаблона',
        'CopyTemplateText': 'Копия шаблона успешно создана',
        'DefaultPopUpsTitle': 'Титул по-умолчанию',
        'DefaultPopUpsText': 'Текст по-умолчанию',
        'StartExperimentTitle': 'Запуск эксперимента',
        'StartExperimentText': 'Эксперимент успешно запущен!',
        'SelectField': 'Выбор поля',
        'WhereToUploadImage': 'Выберите куда добавить изображение',
        'DeleteRecommendationUnitTitle': 'Удаление рекомендованого юнита',
        'DeleteRecommendationUnitText': 'Вы уверены что хотите удалить данный юнит?',
        'ChangeUnitNameTitle': 'Изменение имени юнита',
        'ChangeUnitNameText': 'Внимание! Измения вступят в силу после полного сохранения юнита!',
        'UpdateUnitTitle': 'Сохранение юнита',
        'UpdateUnitText': 'Юнит успешно сохранен в базе',
        'DeleteAbTestGroupTitle': 'Удаление  А/Б тест группы',
        'DeleteAbTestGroupText': 'Вы уверены что хотите удалить данную А/Б тест группу?',
        'ConnectWebsiteBannerScript': 'Подключение скрипта к сайту',
        'ConnectWebsiteBannerScriptMessage': 'Скопируйте и вставьте ссылку скрипта, на Ваш сайт, перед закрывающимся тегом body на главной странице.',
        'CancelChangesTemplateTitle': 'Отмена изменений шаблона',
        'CancelChangesTemplateText': 'Вы уверены что хотите отменить изменения в шаблоне?',
        'CloseChangesTemplateTitle': 'Закрытие редактора шаблона',
        'CloseChangesTemplateText': 'Вы не сохранили изменения шаблона. Закрыть без сохранения?',
        'DeleteSegmentTitle': 'Удаление сегмента',
        'DeleteSegmentText': 'Вы уверены что хотите удалить данный сегмент?',
        'CopySegmentTitle': 'Копирование сегмента',
        'CopySegmentText': 'Копия сегмента успешно создана',
        'SaveSegmentTitle': 'Сохранение сегмента',
        'SaveSegmentText': 'Сегмент успешно сохранен',
        'SaveEditSegmentTitle': 'Подождите пока изменения вступят в силу',
        'SaveEditSegmentText': 'При сохранении изменений в сегменте требуется время для обработки данных. Процесс может составить несколько минут',
        'DeleteIntegrationEventTitle': 'Удаление события',
        'DeleteIntegrationEventText': 'Вы уверены что хотите удалить данное событие?',
        'DeleteIntegrationFieldTitle': 'Удаление поля',
        'DeleteIntegrationFieldText': 'Вы уверены что хотите удалить данное поле?',
        'WarningTitle': 'Предупреждение',
        'WarningSaveEventSchemaText': 'Вы добавляете повторяющиеся поля или поля записи в схему событий. Вы не сможете загружать данные csv для таких событий',
        'DeletePolicyTitle': 'Удаление policy',
        'DeletePolicyText': 'Вы уверены что хотите удалить данный policy?',
        'UpdatedPolicyTitle': 'Сохранение policies',
        'UpdatedPolicyText': 'Policies успешно сохранен!',
        'AddImageTitle': 'Добавить изображение',
        'CreateNewFolder': 'Создать новую папку',
        'CreateNewMaster': 'Создать новый мастер',
        'FolderName': 'Имя папки',
        'MasterName': 'Имя мастера',
        'UploadSelectedImages': 'Загрузка изображений...',
        'Of': 'из',
        'Images': 'изображений',
        'DeleteModalTitle': 'Вы уверены, что хотите удалить?',
        'DeleteFolderLabel': 'Введите имя папки для подтверждения',
        'DeleteMasterLabel': 'Введите имя мастера для подтверждения',
        'InvalidOrEmptyFolderName': 'Недопустимое или пустое имя папки',
        'InvalidOrEmptyMasterName': 'Недопустимое или пустое имя мастера',
        'ImageUploadTechnicalMessage': 'Все изображения больше 5.24 МБ будут автоматически игнорированы. Максимальное количесво загружаемых изображений - 10.',
        'InputRequiredField': 'Обязательное поле',
        'CreateEmailTemplateModalTitle': 'Создать новый шаблон',
        'CreateEmailTemplateModalCodeEditorTitle': 'Через редактор кода',
        'CreateEmailTemplateModalCodeEditorDesc': 'Используйте HTML и JINJA код.',
        'CreateEmailTemplateModalMasterTitle': 'Через мастер',
        'CreateEmailTemplateModalMasterDesc': 'Используйте нашу библиотеку мастеров',
        'SelectMasterTemplateModalTitle': 'Библиотека мастеров',
        'SelectMasterTemplateModalDesc': 'Используйте нашу библиотеку шаблонов',
        'CreateConsentModalInput1Label': 'Имя *',
        'CreateConsentModalInput2Label': 'Описание *',
},
    validationErrorMessages: {
        'CheckboxValidationRequired': 'Необходимо выбрать хотя бы один параметр',
        'InputValidationRequiredMessage': 'Поле обязательно для ввода',
        'SelectValidationRequiredMessage': 'Выберете одну опцию из списка',
        'PasswordValidationMessage': 'Пароль должен быть больше 8 символов',
        'EmailValidationMessage': 'Введена не действительная почта',
        'InputUnacceptableSymbolsValidationMessage': 'В поле присутствуют недопустимые символы',
        'PositiveNumberInputValidationMessage': 'Число должно быть больше нуля',
        'ZeroNumberInputValidationMessage': 'Ноль недопустимое значение. Если данное поле вам не нужно - оставьте его пустым',
        'UrlAddressInputValidationMessage': 'Введите корректный URL адрес',
        'EmailAddressInputValidationMessage': 'Введите корректный E-mail адрес',
        'PhoneInputValidationMessage': 'Данное поле должно быть от 7 до 14 символов',
        'InputTagsValuesOnlyNumericMessage': 'Допустимы только числовые значения',
        'UserDoesNotExist': 'Пользователь не существует',
        'Unauthorized': 'Неправильный пароль',
        'TemplateValidationMessage': 'Необходимо выбрать шаблон',
        'ChooseAtLeastOneOption': 'Необходимо выбрать хотя бы один параметр',
        'SearchUserMessage': 'Необходимо выбрать атрибут из списка'
    },
    actionArrows: {
        'positiveMiddleLabel': 'Да',
        'negativeMiddleLabel': 'Нет'
    },
    websiteBanner: {
        'NameInputLabel': 'Имя *',
        'EmailInputLabel': 'Электронная почта *',
        'PhoneInputLabel': 'Номер телефона *',
        'AgeInputLabel': 'Возраст *',
        'DefaultButtonTextLabel': 'Перейти',
        'DefaultFeedbackTitleMessage': 'Пожалуйста заполните все поля ниже',
        'DefaultFeedbackThanksMessage': 'Спасибо! Мы скоро свяжемся с вами!',
        'DefaultFeedbackButtonText': 'Отправить',
    },
    tableNoData: {
        'OopsLabel': 'Упс',
        'ScenarioListEmptyLabel': 'Сценарии отсутсвуют',
        'CreateScenarioLabel': 'Создать сценарий',
        'ConditionListEmptyLabel': 'Нет условий',
        'CreateConditionLabel': 'Добавить новое условие',
        'SegmentsListEmptyLabel': 'Сегменты отсутсвуют',
        'CreateNewSegment': 'Добавить новый сегмент',
        'ClientsSearchListLabel': 'Найдите клиентов для добавления в сегмент',
        'ClientsEmptyListLabel': 'Клиенты отсутсвуют',
        'AddClientLabel': 'Добавить клиента',
    },
    default: {
        'EnglishLang': 'English',
        'RussianLang': 'Русский (Украина)',
        'UkrainianLang': 'Українська',
        'PolishLang': 'Polski',
        'LoadingMessage': 'Загрузка',
        'SaveButtonTitle': 'Сохранить',
        'CancelButtonTitle': 'Отменить',
        'SkipButtonTitle': 'Пропустить',
        'BackButtonTitle': 'Назад',
        'AcceptButtonTitle': 'Подтвердить',
        'ConfirmButtonTitle': 'Подтвердить',
        'ChoiceButtonTitle': 'Выбрать',
        'ReturnButtonTitle': 'Вернуться',
        'UploadButtonTitle': 'Загрузить',
        'CreatedButtonTitle': 'Создать',
        'CollapseAllButtonTitle': 'Свернуть все',
        'YesButtonTitle': 'Да',
        'NoButtonTitle': 'Нет',
        'DateInputViewerLabel': 'Определенное время *',
        'EditButtonTitle': 'Редактировать',
        'DeleteButtonTitle': 'Удалить',
        'CopyButtonTitle': 'Копировать',
        'LaunchButtonTitle': 'Запустить',
        'SendButtonTitle': 'Отправить',
        'StatisticButtonTitle': 'Статистика',
        'ActionErrorTitle': 'Ошибка!',
        'ActionSaveErrorMessage': 'Необходимо заполнить все требуемые поля в настройках данного Action.',
        'MondayShortcut': 'Пн',
        'TuesdayShortcut': 'Вт',
        'WednesdayShortcut': 'Ср',
        'ThursdayShortcut': 'Чт',
        'FridayShortcut': 'Пт',
        'SaturdayShortcut': 'Сб',
        'SundayShortcut': 'Вс',
        'MonthJanuary': 'Январь',
        'MonthFebruary': 'Февраль',
        'MonthMarch': 'Март',
        'MonthApril': 'Апрель',
        'MonthMay': 'Май',
        'MonthJune': 'Июнь',
        'MonthJuly': 'Июль',
        'MonthAugust': 'Август',
        'MonthSeptember': 'Сентябрь',
        'MonthOctober': 'Октябрь',
        'MonthNovember': 'Ноябрь',
        'MonthDecember': 'Декабрь',
        'ActionTemplates': 'Шаблоны сценария',
        'ChromeImage': 'Изображение для Chrome',
        'WebPushLogo': 'Web push логотип',
        'FirstButton': 'Первая кнопка',
        'SecondButton': 'Вторая кнопка',
        'ActionTemplatePages': 'Список шаблонов',
        'Of': 'из',
        'Close': 'Закрыть',
        'AddButtonLabel': 'Создать',
        'UploadFromPc': 'С компьютера',
        'AddNew': 'Добавить',
        'BackToTemplatesButton': 'Назад к Шаблонам',
        'DeleteFolderButton': 'Удалить папку',
        'DeleteButton': 'Удалить',
        'ApplyAndEditButton': 'Принять и редактировать',
        'DuplicateAndEdit': 'Дублировать и редактировать',
        'LaunchExperimentButton': 'Запустить эксперимент'
    },
    unitStatuses: {
        'AllStatuses': 'Все',
        'DRAFT': 'Черновик',
        'READY': 'Готов',
        'STOPPED': 'Неактивный',
        'ACTIVE': 'Активный',
        'LEARNING': 'Обучение'
    },
    recommendationUnit: {
        'UnitStatus': 'Статус юнита',
        'RecommendationUnits': 'Рекомендационные юниты',
        'Subject': 'Предмет',
        'SubjectRecommendation': 'Предмет рекомендации',
        'SelectSubjectRecommendation': 'Выберите предмет рекомендации',
        'SelectRecommendationType':'Выберите рекомендационный тип',
        'ButtonAddRecommendationSlot': 'Добавить рекомендационный слот',
        'Cells': 'Ячейки',
        'Slot': 'Слот',
        'AbTestGroup': 'А/Б тест группа',
        'TotalNumberCells': 'Общее количество ячеек',
        'CollaborativeFilteringBasedOnViews':'Коллаборативная фильтрация на основание просмотров',
        'CollaborativeFilteringAndDescriptionOfViewed':'Коллаборативная фильтрация и описание просмотренных',
        'EmptySettings':'Пустые настройки',
        'ByFilling':'По заполнению',
        'Empty': 'Пустой',
        'CreateNew': 'Создать новый',
        'PreviouslyUsed':'Ранее использованный',
        'ByClient':'По клиенту',
        'Static':'Статичный',
        'Personal':'Персональный',
        'SelectMetaType':'Выберите мета тип',
        'MostPopularGoods':'Самые популярные товары',
        'NextGoodsToBuy':'Следующий товар к покупке',
        'CrossSell': 'Перекрестная продажа',
        'AccessoriesForSelectedGoods': 'Аксессуары к выбранным товарам',
        'NewType': 'Новый тип',
        'AddConfigurationBlock': 'Добавить конфигурационный блок',
        'LearningTime': 'Время обучения',
        'Ranker': 'Рейтинг',
        'Order':'Порядок'
    },
    subjectRecommendationType: {
        'PRODUCT': 'Товар',
        'STOCK': 'Акция',
        'SET': 'Набор',
        'ARTICLE': 'Статья',
    },
    recommendationMetrics: {
        'Group': 'Группа',
        'Conversion': 'Конверсия',
        'ClientConversion': 'Конверсия клиента',
        'DirectConversion': 'Прямая конверсия',
        'DirectClientConversion': 'Прямая конверсия (клиенты)',
        'CTR': 'CTR',
        'ClientCTR': 'Клиент CTR',
        'AOV': 'AOV',
        'GroupName': 'Имя группы',
        'unitAllTime': 'Юнит за все время',
        'ABGroup': 'А/Б група',
        'ABGroupsAllTime': 'А/Б группы за все время',
        'ABGroupsForPeriod': 'А/Б группы за период',
        'TableNoDataMessage': 'Данные отсутствуют',
        'LeadingClientConversionRate': 'Ведущий коэффициент конверсии клиентов',
        'Significance': 'Значение',
        'NotSignificant': 'Не статистически значимо',
        'LeadingDirectClientConversionRate': 'Ведущий коэффициент прямой конверсии клиентов',
        'Uplift': 'Рост дохода',
        'DirectUplift': 'Прямой рост дохода',
        'EmptyMetricsForDateRange': 'Пустые показатели для диапазона дат'
    },
    tableRecommendationGroup: {
        'NoName': '',
        'RecommendationSlot': 'Рекомендационный слот',
        'Cells': 'Ячейки',
        'Actions': 'Действия',
        'NoSlots': 'Нет слотов',
        'RecommendationType': 'Рекомендационный тип',
        'NumericSymbol': '№',
        'ConfigurationBlocks': 'Конфигурационные блоки',
        'NoRecommendationType': 'Нет рекомендационых типов'
    },
    segments: {
        'NewSegment': 'Новый сегмент',
        'TypeSegments': 'Тип сегмента',
        'StandardSegmentDescription': 'Настройка с помощью условий сегментации',
        'ManualSegmentDescription': 'Настройка по поиску атрибутов клиента',
        'GoThroughTutorial': 'Пройти туториал',
        'GoThroughTutorialDescription': 'Вы научитесь создавать сегмент'
    },
    comparisonSegments: {
        'Segment': 'Сегмент',
        'Segments': 'Сегменты',
        'Clients': 'Клиенты',
        'Add': 'Добавить',
        'Conditions': 'Условия',
        'Comparison': 'Сравнение',
        'TotalClients': 'Всего клиентов:',
        'ARPU': 'ARPU:',
        'AverageCheck': 'Средний чек:',
        'FrequencyPurchasesYear': 'Частота покупок за год:',
        'FrequencyPurchases30Days': 'Частота покупок за 30 дней:',
        'ChurnRate': 'Процент оттока:',
        'TotalClientsYear': 'Всего клиентов за год:',
        'TotalARPUYear': 'ARPU за год:',
        'AverageCheckYear': 'Средний чек за год:',
        'AverageValueSegments': 'Среднее значение сегментов',
        'ValueCurrentSegment': 'Значение данного сегмента',
    },
    conditionSegments: {
        'IncludeToSegment': 'Включить в сегмент',
        'ExcludeFromSegment': 'Исключить из сегмента',
        'Include': 'Включить',
        'Exclude': 'Исключить',
        'Add': 'Добавить',
        'SeparateAnd': 'И',
        'SeparateOr': 'Или'
    },
    clientSegment: {
        'PhoneNumber': 'Номер телефона:',
        'Email': 'Email:',
        'LoyalCardNumber': 'Номер карты:',
        'UniqueUserId': 'Уникальный идентификатор пользователя:',
        'UserName': 'Имя:',
        'LastUpdated': 'Последние изменения:',
        'CommunicationChannels': 'Деятельность других каналов связи',
        'MainTabButton': 'Основная',
        'AdditionalTabButton': 'Дополнительная',
        'Birthday': 'День рождения',
        'Gender': 'Пол',
        'UserId': 'ID пользователя',
        'DeviceId': 'ID устройства',
        'ClientId': 'Пользовательский ID'
    },
    createScenarioSideBar: {
        'TitleNewScenario': 'Новый сценарий',
        'TypeScenario': 'Тип сценария',
        'EmptyScenarioLabel': 'Пустой сценарий',
        'EmptyScenarioDescription': 'Без ранее заданных настроек',
        'LibraryScenario': 'Библиотечный сценарий'
    },
    fileDropzone: {
        'UploadCsvFile': 'Загрузите .csv',
        'UploadJsonFile': 'Загрузите .json',
        'DropFileHereLabel': 'Перетащите файл сюда',
        'Bytes': 'байтов',
        'DragAndDrop': 'Перетащите',
        'YourImage': 'Ваше изображение',
    },
    integrationsTable: {
        'TableColumnEventName': 'Название события',
        'TableColumnFieldName': 'Имя поля',
        'TableColumnType': 'Тип',
        'TableColumnIsRequired': 'Обязательное',
        'TableColumnActions': 'Действия',
        'TableColumnIsInternal': 'Является внутренним',
        'TableColumnTopic': 'Тема',
    },
    integrations: {
        'UploadOrdersLabel': 'Загрузка заказов',
        'UploadOrderItemsLabel': 'Загрузка позиции товаров',
        'UploadCustomersLabel': 'Загрузка клиентов',
        'UploadProductsLabel': 'Загрузка продуктов',
        'ViewSchemaButtonLabel': 'Просмотр схемы',
        'FieldNameLabel': 'Имя поля *',
        'TypeLabel': 'Тип *',
        'RequiredFieldLabel': 'Обязательное поле',
        'UploadCsvHeaderTitle': 'Загрузите CSV',
        'LeftButtonUploadCsvHeaderTitle': 'Начать загрузку',
        'RightButtonUploadCsvHeaderTitle': 'Назад',
        'CreateDataMappingForEvent': 'Создайте сопоставление данных для события',
        'CsvUploadTuningSectionNotice': '* Убедитесь, что загруженная таблица .csv включает имена столбцов в первой строке',
        'SetupCsvUploadNotice': '** Первые 20 строк отображаются только в качестве примера. Все данные будут загружены',
        'CsvTableDropBoxLabel': 'Перетащите сюда',
        'UploadCsvProcessTitle': 'Загрузка csv таблицы',
        'ApiFailureUploadCsvProcessTitle': 'Ошибка загрузки. Попробуйте еще раз',
        'IngestionProcessLabel': 'Процесс загрузки',
        'SuccessfullyUploadMessageFirstPart': 'Успешно получено',
        'SuccessfullyUploadMessageSecondPart': 'событий из csv файла',
        'IntegrationsPageTitle': 'Интеграции',
        'SeeAllEventsButton': 'Посмотреть все события',
        'GoBackToUploadButton': 'Вернуться к загрузке',
        'AddNewEventSidebarTitle': 'Новое событие',
    },
    frequencyPolicies: {
        'NewPolicyTitle': 'Новый полиси',
        'PolicyNameLabel': 'Имя *',
        'PolicyMaximumCommunicationsLabel': 'Максимум коммуникаций *',
        'PolicyFrequencyLabel': 'Частота *',
        'FieldName': 'Имя',
        'MaximumCommunications': 'Максимум коммуникаций',
        'Frequency': 'Частота',
        'ScenariosWithPolicy': 'Сценарии с полиси',
        'Actions': 'Действия',
        'CommunicationFrequencyTitle': 'Настройка частоты коммуникаций',
    },
    mainPage: {
        'Welcome': 'Добро пожаловать',
        'WelcomeDesc': 'Изучите жизненный цикл ваших клиентов с помощью аналитики удержания клиентов NeuCurrent',
        'MainPageTitle': 'Анализ вашей клиентской базы',
        'MainPageDesc': 'Мы пропустим ваши данные через нашу аналитическую систему, рассчитаем показатели удержания, поймем ваши текущие проблемы и предложим решения',
        'Circle1Desc': 'Поймите пожизненную ценность ваших клиентов',
        'Circle2Title': 'Динамика клиентов',
        'Circle2Desc': 'Поймите жизненный цикл вашего клиента',
        'Circle3Title': 'Доход',
        'Circle3Desc': 'Поймите основные факторы дохода',
        'MainPageSubTitle': 'Стратегия удержания клиентов',
        'MainPageSubDesc': 'Анализируя ваши данные, мы выявляем наиболее важные проблемы и формируем стратегию удержания для их решения.',
        'Critical': 'Критический',
        'Risk': 'Риск',
        'Opportunity': 'Возможность',
    },
    mainPageDashboard: {
        'Welcome': 'Ваша индивидуальная стратегия удержания',
        'WelcomeDesc': 'Изучите свои проблемы с удержанием и начните взаимодействие с клиентами',
        'RetentionStrategy': 'Стратегия удержания',
        'StartWithLabel': 'Начните',
        'isNotReadySectionMessage': 'Анализируя ваши данные, мы выявим наиболее важные проблемы и создадим стратегию хранения для их решения.',
        'isReadySectionMessage': 'Анализируя ваши исторические данные о транзакциях, мы обнаруживаем проблемы с удержанием клиентов, которые значительно влияют на ваш доход.',
        'Critical': 'Критический',
        'Risk': 'Риск',
        'Opportunity': 'Возможность',
        'DropdownSectionCriticalDesc': 'Проблемы, сильно влияющие на продажи. Требуют вашего немедленного внимания.',
        'DropdownSectionRiskDesc': 'Возможные критические проблемы. Требовать вашего внимания, чтобы не допустить перерастания в существенные негативные тенденции.',
        'DropdownSectionOpportunityDesc': 'Возможности для будущего роста продаж.',
        'DropdownCardInterval': 'Ежемесячно',
        'DropdownCardStatusNew': 'Новый',
        'DropdownCardStatusScenarioLaunched': 'Сценарий запущен, ждем результатов',
        'DropdownCardStatusFirstResults': 'У нас есть первые результаты',
        'DropdownCardStatusResolved': 'Решено',
        'LtvDesc': 'Доход, который каждый клиент приносит вам. Стремитесь увеличить LTV',
        'LtvCard1': 'Продолжительность жизни в годах',
        'LtvCard2': 'Частота покупок в год',
        'RevenueTitle': 'Доход',
        'RevenueDesc': 'Ваши основные источники дохода за последние 30 дней',
        'RevenueCard1': 'Активные клиенты за последние 30 дней',
        'RevenueCard2': 'Частота периода',
        'CustomerDynamicsTitle': 'Клиентская база',
        'PopUpCardTitle': 'Описание метрики',
        'CustomerDynamicsElem3': 'Клиенты с разовой покупкой',
        'CustomerDynamicsElem6': 'Новые клиенты',
        'CustomerDynamicsElem8': 'Активировано',
        'CustomerDynamicsElem10': 'Риск оттока',
        'CustomerDynamicsElem14': 'Подписки',
        'CustomerDynamicsElem16': 'Утраченные',
        'CustomerDynamicsElemDays': 'дней',
        'CustomerDynamicsElemDaysAgo': 'дней назад',
        'CustomerDynamicsElemDaysBefore': 'дня до этого',
        'CustomerDynamicsElem3Message1': 'Совершил 1 покупку и совершил более',
        'CustomerDynamicsElem3Message2': 'дней назад, но меньше',
        'CustomerDynamicsElem6Message1': 'Имеет 1 покупку и покупка была совершена меньше или равна',
        'CustomerDynamicsElem8Message1': 'Имеет 2 или более покупок, и последняя покупка была совершена менее чем',
        'CustomerDynamicsElem10Message1': 'Имеет хотя бы одну покупку, которая была совершена больше или равно',
        'CustomerDynamicsElem10Message2': 'дней и менее',
        'CustomerDynamicsElem14Message1': 'Имеет хотя бы один прямой канал связи и не совершает покупок',
        'CustomerDynamicsElem16Message1': 'Совершил хотя бы одну покупку, которая была совершена больше или равно',
        'CustomerDynamicsElem1Message1': 'Новые клиенты за последние',
        'CustomerDynamicsElem2Message1': 'Сингл за последние',
        'CustomerDynamicsElem2Message2': 'Новые',
        'CustomerDynamicsElem4Message1': 'Риск оттока от одного по последнему',
        'CustomerDynamicsElem4Message2': 'Прямой',
        'CustomerDynamicsElem5Message1': 'Активен с сингла за последние',
        'CustomerDynamicsElem5Message2': 'Новые',
        'CustomerDynamicsElem7Message1': 'Активны из новых в течение последних',
        'CustomerDynamicsElem7Message2': 'Новые за последнее',
        'CustomerDynamicsElem9Message1': 'Риск оттока из Активных за последние',
        'CustomerDynamicsElem9Message2': 'Активный',
        'CustomerDynamicsElem11Message1': 'Новые, Одиночные, Активные от подписчиков за последнее',
        'CustomerDynamicsElem11Message2': 'Подписчики',
        'CustomerDynamicsElem12Message1': 'Активен из-за риска оттока в течение последних',
        'CustomerDynamicsElem12Message2': 'Риск оттока',
        'CustomerDynamicsElem13Message1': 'Сбиты с толку из-за риска сбивания последних',
        'CustomerDynamicsElem13Message2': 'Риск оттока',
        'CustomerDynamicsElem15Message1': 'Активен из-за оттока за последние',
        'CustomerDynamicsElem15Message2': 'Отток',
        'CustomerDynamicsElem17Message1': 'Новые подписчики за последние',
    },
    calendar: {
        'AllTimeButton': 'Все время',
        'ThisYearButton': 'Этот год',
        'ThisMonthButton': 'Этот месяц',
        'ThisWeekButton': 'Эта неделя',
        'ClearButton': 'Очистить',
        'AppleButton': 'Применить',
        'Selected': 'Выбрано',
        'Days': 'дней'
    },
    clientDashboardPage: {
        'InformationAboutClientTitle': 'Информация о клиенте',
        'ConsentsTitle': 'Согласие клиента',
        'ClientKpiTitle': 'Статус клиента',
        'TableOfEvents': 'События',
        'FilterDropdown': 'Фильтр',
        'SortDropdown': 'Сортировка',
        'PeriodButton': 'Период:',
        'PeriodButtonAllTimeLabel': 'Все время',
        'ActivityStatusLabel': 'Статус активности',
        'SubscriptionDateLabel': 'Дата подписки',
        'OrderDateLabel': 'Дата заказа',
        'FirstLabel': 'Первый',
        'LastLabel': 'Последний',
        'Order12MonthFrequencyStatusLabel': 'Статус частоты заказов за 12 месяцев',
        'OrdersCurrentMonthStatusLabel': 'Статус заказов на текущий месяц',
        'CustomerMainOrderSourceLabel': 'Источник основного заказа клиента',
        'CustomerMainPosLabel': 'Основной POS клиента',
        'OrderMetricsTitle': 'Метрики заказов',
        'OverallColumn': 'Общий',
        'Last12MonthColumn': 'Последние 12 месяцев',
        'CurrentPeriodColumn': 'Текущий период',
        'OrderCountRow': 'Количество заказов',
        'OrdersSumRow': 'Сумма заказов',
        'AovRow': 'AOV',
        'OrdersProductsQuantityRow': 'Количество заказанной продукции',
        'CommunicationChannelActive': 'Клиент переходил через канал хотя бы один раз за последние 3 месяца',
        'CommunicationChannelNotActive': 'Сообщения отправлены в этот канал, но клиент не прошел через канал',
        'CommunicationChannelUndefined': 'Статус канала неизвестен (за последние 12 месяцев на этот канал не было доставлено сообщений)',
        'CommunicationChannelFailed': 'Невозможность доставки сообщений по этому каналу',
        'CommunicationChannelNotAvailable': 'Этот атрибут недоступен для клиента',
        'TooltipExternalEvents': 'События, вызванные активностью клиентов за пределами платформы NeuCurrent. Пользователи платформы также могут добавлять на платформу свои индивидуальные события. Все остальные события, не отнесенные к категории, являются внешними.',
        'TooltipWebsiteActivity': 'Активность клиентов на сайте',
        'TooltipMobileAppActivity': 'Действия клиентов в приложении',
        'TooltipCommunications': 'События, связанные с общением с клиентами',
        'TooltipPlatformEvents': 'События, которые запускаются на платформе'
    },
    iconicDropdown: {
        'Newest': 'Новые',
        'Oldest': 'Старые'
    },
    templatesSetupAside: {
        'MasterAsideTitle': 'Ваши мастер-настройки',
        'MasterAsideDesc': 'Настройте свой основной шаблон, заполнив все поля ниже',
        'ImagesAsideTitle': 'Ваши изображения',
        'ImagesAsideDesc': 'Выберите, загрузите и удалите необходимые изображения в папках ниже. Кликните на изображение, чтобы скопировать URL-адрес в буфер обмена.',
        'AsideImagesFoldersTitle': 'Загрузите свои собственные изображения',
        'SettingsAsideTitle': 'Настройки шаблона',
        'SettingsAsideDesc': 'Заполните все поля ниже для настройки вашего шаблона',
        'TestAsideTitle': 'Тестировать ваш шаблон',
        'TestAsideDesc': 'Заполните все поля ниже и протестируйте ваш шаблон'
    },
    policies: {
        'PoliciesLabel': 'Политика',
        'PoliciesDesc': 'Установите частоту общения и требования к согласию для своих клиентов',
        'FrequenciesButtonTitle': 'Частоты',
        'FrequenciesButtonDesc': 'Как часто вы хотите отправлять сообщения своим клиентам?',
        'ConsentsButtonTitle': 'Согласия',
        'ConsentsButtonDesc': 'Установите требования к согласию для своих клиентов',
    },
    metricViewPage: {
        'LaunchExperimentButton': 'Запустить эксперимент',
        'ProblemsListSectionTitle': 'Проблеми, в яких є ця метрика',
        'PastYearLabel': 'Прошлый год',
        'CurrentYearLabel': 'Текущий год',
        'DetectedProblemLabel': 'Обнаружена проблема',
    }
};
